import React, { Component } from 'react';
import { Link, Routes, Route, HashRouter as Router } from 'react-router-dom';
import styles from './hamburger.module.css';



export class Hamburger extends Component
 {
  constructor(props)
  {
    console.log('constructor');
    super(props);
    this.state          = { isOpen: false, };
    this.click_text     = true;
    this.resetMenu      = this.resetMenu.bind (this);    
    this.resetMenuHome  = this.resetMenuHome.bind (this);
  }


 resetMenu()
 {
  this.setState({isOpen : false});
 }
 
 resetMenuHome()
 {
  console.log("Reset menue Home");
  this.setState({isOpen : false});
  this.click_text = true;
 }


  toggleMenu = () => 
  {
    this.click_text = false;
    this.setState({ isOpen: !this.state.isOpen });
  };


  toTop = () => 
  {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  renderMenu() 
  {
    //console.log("Render hamburger");
    return (
      <div className={styles.hamburger_backdrop}>

        <button className={styles.hamburger_icon} onClick={this.toggleMenu}>
          &#9776;
        </button>
        <div className={styles.menu_list}>
          <ul>
            <li><Link to="/"                  onClick={this.resetMenuHome}        >Home</Link></li>
            <li><Link to="/overview_portrait" onClick={this.resetMenu}        >Overview</Link></li>
            <li><Link to="/story"             onClick={this.resetMenu}        >Story</Link></li>
            <li><Link to="/products"          onClick={this.resetMenu}        >Products</Link></li>
            <li><Link to="/investors"         onClick={this.resetMenu}        >Investors</Link></li>
            <li><Link to="/publications"      onClick={this.resetMenu}        >Publications</Link></li>           
            <li><Link to="/contact"           onClick={this.resetMenu}        >Contact</Link></li>
            <li><Link to="/about"             onClick={this.resetMenu}        >About</Link></li>
          </ul>
        </div>
      </div>
    );
  }

  render() 
  {
    return this.state.isOpen ? this.renderMenu() : (
        <div>
        <button className={styles.hamburger_icon} onClick={this.toggleMenu}>
        &#9776; 
        { this.click_text && <span className={styles.hamburger_text_white}> click here </span>}
        </button>
      </div>
    );
  }
}




























/*



import React, { Component } from 'react';
import { Link }             from 'react-router-dom';
import styles               from './hamburger.module.css'; 
import { TbRuler } from 'react-icons/tb';
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import Home                 from "../pages/home";
import { OverviewPortrait } from "../pages/overview_portrait.jsx";
import { Products }         from "../pages/products.jsx";
import { About }            from "../pages/about-us";
import { Investors }        from "../pages/investors.jsx";
import ContactForm          from '../pages/contact_form'; 
import { Story }            from '../pages/story.jsx';
import pic_home_portrait2 from '../pictures/Backdrop Image Portrait2.jpg';

export class Hamburger extends Component 
{
  //------------------------------------------------------------------------
  //    Constructor
  //------------------------------------------------------------------------
  constructor(props) 
  {
    console.log('construtor hamburger');
    super(props);

    this.state    = { isOpen: false };
      
    this.selected = 
    {
        home:false,
        overview: false,
        story: false,
        products: true,
        investors: false,
        contact: false,
        about: false
    };
    
    this.expanded = true;
    this.component = '';
    this.toggleMenu = this.toggleMenu.bind(this);
    this.prepare_for_rendering = this.prepare_for_rendering.bind(this)
  }

  //------------------------------------------------------------------------
  //
  //------------------------------------------------------------------------
  componentDidMount() 
    {
        console.log('Component did mount in Hamburger');
        //this.prepare_for_rendering();
        //this.forceUpdate();
    }


  //------------------------------------------------------------------------
  //
  //------------------------------------------------------------------------
   toggleMenu = () => 
  {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));

    console.log('Hamburger toggle', this.expanded, this.state.isOpen);
   
    this.expanded = !this.expanded;

    console.log('toggle:', this.expanded, this.state.isOpen );
    
   // this.prepare_for_rendering();
   // this.forceUpdate();
  }

 
  //------------------------------------------------------------------------
  //
  //------------------------------------------------------------------------
  toTop(event) 
  {
      console.log('toTop event: ', event);

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      Object.keys(this.selected).forEach(key => 
      {
          this.selected[key] = false;
      });

      //this.selected[event] = true;
     //this.prepare_for_rendering();
     //this.forceUpdate();
  }

  //------------------------------------------------------------------------
  //
  //------------------------------------------------------------------------
  prepare_for_rendering()
  { 

    console.log('prepare for render 1', this.expanded);

    if (this.state.isOpen === false)
    {
      this.component = 

        <div  className={styles.hamburger_backdrop}>

              <button className={styles.hamburger_icon} onClick={this.toggleMenu}>
                  &#9776;
              </button>
              <Home/>
        </div>  
    }
    else
    { 
      console.log('use link', this.expanded);

      this.component = 

              <div > 

                <button className={styles.hamburger_icon} onClick={this.toggleMenu}>
                    &#9776;
                </button>

               <Router>
               <div className={styles.menu_list}>
                  <ul>
                    <li><Link to="/"                    onClick={this.toggleMenu}>Home</Link></li>
                    <li><Link to="/overview_portrait"   onClick={this.toggleMenu}>Overview</Link></li>
                    <li><Link to="/story"               onClick={this.toggleMenu}>Story </Link></li>
                    <li><Link to="/products"            onClick={this.toggleMenu}>Products</Link></li>
                    <li><Link to="/investors"           onClick={this.toggleMenu}>Investors</Link></li>
                    <li><Link to="/contact"             onClick={this.toggleMenu}>Contact</Link></li>
                    <li><Link to="/about"               onClick={this.toggleMenu}>About</Link></li>
                  </ul>
                </div>

                <Routes>
                        <Route path="/"                      element={<Home/>} />   
                        <Route path="/overview_portrait"     element={<OverviewPortrait portrait/>} />        
                        <Route path="/story"                 element={<Story portrait />} />        
                        <Route path="/products"              element={<Products portrait />} />        
                        <Route path="/investors"             element={<Investors portrait/>} />        
                        <Route path="/contact"               element={<ContactForm portrait/>} />        
                        <Route path="/about"                 element={<About portrait/>} />        
                    </Routes>              </Router>
            </div>    
    };   
  }


  render() 
  {  
    console.log('render', this.component);
    return this.component;
  }
}

*/


