


import React, { Component } from 'react';
//import './App.css';
import { Routes, Route, HashRouter as Router } from "react-router-dom";

import styles               from './navigation.module.css';
import { Link }             from "react-router-dom";
import {Context}            from './services/context.js'
import Home                 from "./pages/home";
import { Overview }         from "./pages/overview";

import { Products }         from "./pages/products.jsx";
import { About }            from "./pages/about-us";
import { Investors }        from "./pages/investors.jsx";
import ContactForm          from './pages/contact_form'; 
import { Story }            from './pages/story.jsx';
import {Publications}       from './pages/publications.jsx';


export class Navigation extends Component
{
    //---------------------------------------------------------
    //  Constructor
    //---------------------------------------------------------
    constructor(props) 
    {
        super(props);

        console.log('Props', props);

        this.expanded = false;
        this.portraitMode = Context.portrait;

        console.log('Is it portrait Mode:', this.portraitMode);


        // Initialize the selected object with all keys set to false
        
        this.selected = 
        {
            home:false,
            overview: false,
            story: false,
            products: false,
            investors: false,
            publications : false,
            contact: false,
            about: false
        };

    }

    componentDidMount() 
    {
        console.log('Component did mount in Navigation');
        this.prepare_for_rendering();
        this.forceUpdate();
    }


    toTop(event) 
    {
        console.log('toTop event: ', event);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
/*
        Object.keys(this.selected).forEach(key => 
        {
            this.selected[key] = false;
        });

        this.selected[event] = true;
 */      

        this.prepare_for_rendering();
        this.forceUpdate();
    }
 
    //                                                                   
    //----------------------------------------------------------------
    //  Render for Phones or for Desktop
    //----------------------------------------------------------------
    prepare_for_rendering()
    {
     
        /*
        if (this.portraitMode)
        {
            console.log('portraitMode in navigation rendering');

          this.component =  <Hamburger/>;
           
        }
        else
        {
            */
        //-----------------------------------------------------
        //  Normal desktop and tablet displays in landscape mode
        //-----------------------------------------------------
        //data-selected={this.selected.products}  
            this.component = 

            <div>

                <Router>    
                    <div className = {styles.navigation} >
                        <p className= {styles.button}  onClick={() => this.toTop( 'home')} >         <Link to = "/"                >  Home            </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'overview')} >     <Link to = "/overview"        >  Overview        </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'story')} >        <Link to = "/story"           >  Story           </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'products')} >     <Link to = "/products"        >  Products        </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'investors')} >    <Link to = "/investors"       >  Investors       </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'publications')}>  <Link to = "/publications"    >  Publications     </Link></p>                        
                        <p className= {styles.button}  onClick={() => this.toTop( 'contact')} >      <Link to = "/contact"         >  Contact         </Link></p>
                        <p className= {styles.button}  onClick={() => this.toTop( 'about')} >        <Link to = "/about"           >  About           </Link></p>
                    </div>

                    <br/>

                    <Routes>
                        <Route path="/"                element={<Home        />} />        
                        <Route path="/overview"        element={<Overview   />} />        
                        <Route path="/story"           element={<Story   />} />        
                        <Route path="/products"        element={<Products />} />
                        <Route path="/investors"       element={<Investors />} />
                        <Route path="/publications"    element={<Publications />} />
                        <Route path="/contact"         element={<ContactForm/>} />        
                        <Route path="/about"           element={<About />} />        
                    </Routes>
                </Router>

            </div>        
        
    }    

    render() 
    {
      return  this.component ;
    }
}


//                        <Route path="/products"              element={<Products portrait />} />   
