import React, { Component } from 'react';
import styles from '../components/textBlock.module.css';


class TextBlock extends Component {
    render()
    {
      const { textLines } = this.props;

      return (
        <div className={styles.text_block}> 
          {textLines.map((line, index) => (
            <p className={styles.p} key={index}>{line}</p>
          ))}
        </div>
      );
    }
  }
  
  export default TextBlock;

 