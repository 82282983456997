import React     from 'react'; 

import Newspaper  from '../components/Newspaper.jsx';
import Newspaper_4_col     from '../components/Newspaper_4_col.jsx';
import Paper      from '../components/Paper.jsx';
import styles     from './how_it_works.module.css';
import {Context}  from '../services/context.js'
import { Helmet }           from 'react-helmet';


import pic_1 from '../pictures/Full Block 2.png';
import pic_2 from '../pictures/LionToken.Net.jpg';
import pic_3 from '../pictures/Blockchain Paper Diagrams-01.png';
import pic_4 from '../pictures/Begin of Chain_Begin of chain.png';
import pic_5 from '../pictures/Blockchain Foldback_Front of chain.png';
import pic_6 from '../pictures/Block encryption with modifier.png';
import pic_7 from '../pictures/Block decryption with modifier.png';



    const text_1  = [<h4>How does Back-Step work</h4> ,
    <p>We assume here that you know how traditional blockchains work.</p>,
    <p> I all blockchains a hash value over the contents of a block is feed forward as an input to the next field. 
      This guarantees that every modification of an existing field changes the contents of all following hash values. But this alone is not enough. If someone modifies an existing block 
      and calculates all following blocks new and claims then that his chain is the original one the security is totally lost. This is the reason why special measures are 
      required to prevent such modifations. The solutions known previously require a lot of computation work and / or a lot of network nodes which are all completely 
      independant of each other. This limits blockchain applications where these requirenments are not critical. There is another factor which is often overloked. 
      When a blockchain is replicated at 10.000 nodes, each node need storage to store the complete chain. The cost of this storage is at the end paid by the users, 
      most time via transaction fees. Back-Step Blockchain uses a different mechanism to secure the chain. Each record gets a 32 byte backstep value from a cryptographic generartor 
      which is then part of the block. The specialty is that the backstep generator value of previous block can be easy calculated from the current block but not vice versa.
      This implements a chain from the current block back to the very first block of the chain. Nobody can know what the next value will be and therfor cannot maintain a faked chain.
      This is the basic principle. More details can be seen from the patent documents. Both operations, calculating the next backstep value and calculating the forward hash are fast 
      with a determined number of cycles. The diagrams show the details of the information flow in the chain. 
    </p>];
    
    const text_2  = [ <h4>Chain on Chain - Parent/Child Mode</h4>,
     <p>The Back-Step Blockchain can hold long blocks. In principle the length of the block could be determined by the application requirenments. The concept allows blockchains 
      where each block has a different size. We call this variable length chains. For the first hardware we will provide a fixed length of 1232 byte. Out of this arround 1000 byte 
      are available for the user data, called payload. This paylod is hashed first and then placed into the block. There is no previous payload hash used. Instead the payload hash value 
      is multiplied by the pervious hash value and reduced modulo p (highest prime below 2^32). This value, we call it MoMu is part of the block and hashed. If multiple chains (reudndancy) 
      feed in the payload in reverse order the MoMu is identical after all payload blocks are inserted, independent of the order. The order of the multiplication is irrelevant. 
      This helps to synchronize multiple chains. Example: Three chains feed in the same payload blocks, but in random order. After all blocks are feed the MoMu value must be identical, 
      while the block hash is definitly not.
      <br/>
      Now it is possible to place other chains as paylod into the Back-Step Blockchain. These other chain can the disable their block security mechanism and participate from the 
      performance advantages of the Back-Step Blockchain.   
    

    </p>];
    
    const text_3 = [ <h4>Security by chip...</h4>,
    <p>The core of the Back-Step Blockchan is a generator inside a protected chip. The technology is similar to what you know from the chips in your credit card or your bank card.
    This provides the state of the art security. Details can be found in the patents . Beside the prototypes we will provide 3 different versions of the product. 
    The entry level Generator consitst of a USB-C connected token  with a limit of 1000 blocks per second. Customers need their own server with network and storrage connections. 
    Two higher performance products are under development one for 5000 blocks/s and one for above 10.000 blocks per second. In cases where higher speed is required multiple 
    transactions can be combined into a single block. Most cryptocurrencies use this technologies, beause their blockchains are not fast enough.
    Because harware can fail, it is recommended to build redundancy, preferred with 2 out of 3 or 3 out of 5 solutions. </p>];
    

    const text_4 = [ <h4>Do we need networks? </h4>,
    <p> Yes and No. In an application where a Back-Step Blockchain is integrated in a ID card or an passport only an application interface is needed, often wireless. 
        For server based applications the Back-Step Blockchain should be implemented in a redundancy mode to connect to 2 or 4 other Back-Step Blockchains in a redundant pool. 
        Here a high speed connection is preferrable. There might be also application where the customer wants chains per country, region or even city. 
        If the block input to the chain is provided by local network users or via internet a network connection with the required performance is required. 
        But there is absolutely no need of huge clusters with thousands of nodes for security purpose as known from traditional blockchain systems.   
    </p> ];
    
   const text_5 = [ <h4>How does redundancy work? </h4>,
    <p> To explain this we take a 2 out of 3 concept. Three Back-Step Blockchain systems are installed preferable at different locations. When a system is initialized the 
      Back-Step Generator is inotialized with random values no one knows. Thid means we have three chains with complete different hash and Back-Step values. 
      Each chain can be verified by the other servers or b anybody who wants to do that. If the system is not manipulated all 3 systems validate sucessful. </p>,
      <p> Beside the individual running security values for each system the payload and the payload hash or if MoMU is used the MoMu values are identical among the three systems. 
        It is not possible that the payload is manipulated undetected on a sytem. It would be detected by the automatic synchronisation process by the two other systems. 
        Furthermore the block hash and the Back-Step values would detect the manipulation.   
    </p> ];
    
const text_6 = [ <h4> Encryption for your data </h4>,
 
    <p>Your encrypted data is displayed on the blockchain, allowing everyone to see the encrypted bytes. Naturally, only you can access the plain, decrypted data. 
      However, entities such as hackers might be interested in attempting to decrypt these bytes, potentially to gain access to sensitive information like your 
      Bitcoin key. We anticipate that experts equipped with the latest cryptoanalytic skills will challenge your data’s security. Our encryption is designed to 
      withstand such professional attacks, ensuring your data remains safe. This level of security extends beyond what typical encryption libraries offer. 
      Relying on secrecy in your encryption design isn’t sufficient; robust security measures must remain effective even when the design is public. We have implemented 
      two distinct encryption algorithms, each building on the well-studied data flow principles of AES, which we believe has been thoroughly analyzed over the past 20 
      years. Initially, the data are encrypted in 16-byte segments (hereafter referred to as 'data units' to avoid confusion with blockchain blocks) using standard AES in
       CBC mode, with two unique 256-bit key for each block. After the initial encryption, all data units are XORed with a modifier constructed from the data itself. 
       The data units are then encrypted in reverse, starting from the last unit to the first, using a separate, independently generated session key. This backward 
       encryption phase can extend beyond the standard 14 rounds. Additionally, we can vary the S-Boxes used in the backward encryption with content of varying strength. 
       An algorithm generator in the hardware of our patented Back-Step generator produces the algorithm for Trust.it, using a random seed unknown to us.
        We believe this combination of techniques provides a trusted environment for your data. Now you understand why a block ensuring privacy incurs an additional Fee.</p>
  ];
  
  




  export class HowItWorks extends React.Component  
  { 

      //------------------------------------------------------------------
      //  Constructor
      //------------------------------------------------------------------
      constructor(props) 
      {
        super(props);
        this.component= "";
      }  
      
  
      componentDidMount()
      {
        // console.log('Component did mount in About Us');
        this.prepare_for_rendering();
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});
      };
      
      // className={styles.backdrop}
      //---------------------------------------------------------------------------
      //  Prepare for portrait devices first
      //---------------------------------------------------------------------------
      prepare_for_rendering()
      { 
        if (Context.portrait)
        {
            this.component = 
              <div className={styles.backdrop_portrait}>
                    <Helmet>
                     <title>How Back-Step Blockchain works</title>
                      <meta name="description" content="Detailed explanation of the invention with diagrams and flowcharts" />
                      <meta charset="utf-8" />
                    </Helmet>

    
                    <div className={styles.grid_header_portrait}>
                    <h4>How Back-Step Blockchains work</h4>
                    </div>


                    <div className={styles.grid_item}> 
                    < Paper textLines = {text_1}/> 
                    </div>

                    <div className={styles.grid_image_portrait}>
                      <p>The basic Back-Step idea which lead to the patent applications</p>
                      <img src={pic_3} width='100' alt="pic_introduction" />
                    </div>

                    <div className={styles.grid_image_portrait}>
                      <p>Hash and Back-Step value flow into different directions</p>
                      <img src={pic_4} width='100' alt="pic_introduction" />
                    </div>

                    <div className={styles.grid_image_portrait}>
                      <p>Feedback link of the first Back-Step value to the hash chain</p>
                      <img src={pic_5} width='100' alt="pic_introduction" />
                    </div>

                    <div className={styles.grid_item}>
                      <Paper  textLines = {text_2}/>
                    </div>

                    <div className={styles.grid_image_portrait}>
                      <p>Diagram with payload hash and block hash</p>
                      <img src={pic_1} width='250' alt="pic_introduction" />
                    </div>
                    
                  
                    <div className={styles.grid_item}>
                      <Paper  textLines = {text_3}/>
                    </div>

                    <div className={styles.grid_image_portrait}>
                      <p>The first prototype of the Back-Step Generator (before Covid-19)</p>
                      <img src={pic_2} width='100' alt="pic_introduction" />
                    </div>


                    <div className={styles.grid_item}>
                      <Paper  textLines = {text_4}/>
                    </div>

                    <div className={styles.grid_item}>
                      <Paper  textLines = {text_5}/>
                    </div>

                    <div className={styles.grid_item}>
                      <Paper textLines = {text_6}/>
                    </div>

                    <div  className={styles.grid_image_portrait}>
                      <img src={pic_6}  width='250' alt="encryption concept" />
                  </div>
            
                  <div  className={styles.grid_image_portrait}>
                    <img src={pic_7}  width='250' alt="decryption concept" />
                  </div>

                  </div>
           
        }
        else
        //---------------------------------------------------------------------------
        //  Prepare for Landscape devices herefirst
        //---------------------------------------------------------------------------
        {
          this.component = 
          <div className={styles.backdrop}>
  
                    <Helmet>
                     <title>How Back-Step Blockchain works</title>
                      <meta name="description" content="Detailed explanation of the invention with diagrams and flowcharts" />
                      <meta charset="utf-8" />
                    </Helmet>

              <div className={styles.grid_container}>
  
                <div className={styles.grid_header}>
                <h4>How the Back-Step Blockchain works</h4>
                </div>


                <div className={styles.grid_item}> 
                < Newspaper textLines = {text_1}/> 
                </div>

                <div className={styles.grid_image_right}>
                  <p>The basic Back-Step idea which lead to the patent applications</p>
                  <img src={pic_3} width='100' alt="pic_introduction" />
                </div>

                <div className={styles.grid_image_left}>
                  <p>Hash and Back-Step value flow into different directions</p>
                  <img src={pic_4} width='100' alt="pic_introduction" />
                </div>

                <div className={styles.grid_image_right}>
                  <p>Feedback link of the first Back-Step value to the hash chain</p>
                  <img src={pic_5} width='100' alt="pic_introduction" />
                </div>

                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_2}/>
                </div>

                <div className={styles.grid_image_right}>
                  <p>Diagram with payload hash and block hash</p>
                  <img src={pic_1} width='250' alt="pic_introduction" />
                </div>
                
              
                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_3}/>
                </div>

                <div className={styles.grid_image_right}>
                  <p>The first prototype of the Back-Step Generator (before Covid-19)</p>
                  <img src={pic_2} width='100' alt="pic_introduction" />
                </div>


                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_4}/>
                </div>

                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_5}/>
                </div>

                <div className={styles.grid_item_wide}>
                  <Newspaper_4_col  textLines = {text_6}/>
                </div>

                <div  className={styles.grid_item_wide}>
                  <img src={pic_6} className={styles.rounded_img} width='250' alt="encryption concept" />
              </div>
        
              <div  className={styles.grid_item_wide}>
                <img src={pic_7} className={styles.rounded_img} width='250' alt="decryption concept" />
              </div>

              </div>
        </div>

        }
      }
      render()
      {
        return this.component ;
      }
  
}