import React           from 'react'; 
//import Container from 'react-bootstrap/Container';
import styles          from './what_is_BSB.module.css';
import Newspaper       from '../components/Newspaper.jsx';
import {Context}       from '../services/context.js'
import Paper           from '../components/Paper.jsx';
import { Helmet }           from 'react-helmet';

    const text_1  = [<h4>Back-Step Blockchain</h4> ,
    <p>Back-Step Blockchain is an invention, patented in many countries, that brings substantial advantages to blockchain technology.
     As blockchains are the underlying technology for many applications like cryptocurrencies it has the potential to dramatically improve existing applications.
     But even more important, Back-Step Blockchain has the potential to enable thousands of applications that were not suitable for traditional blockchains.
    </p>,
    <p>The newly developped cryptographic concept provides faster operations like thousands of blocks per second. This technology can be implemented in a single chip, like in a passport.
    As a side effect very little power is required. Yes, this is bad news for mining farms. A Back-Step Blockchain operates autonomously without a network of servers. 
    All this together is the base for new applications. Further the Back-Step Blockchain can host blocks of other blockchains in their payload field. 
    This is similar to Virtual Machines in a computer. It can be understood as parent / child concept where the parent can hold the data of many different child blockchains.</p>];
    
    const text_2  = [ <h4>Your first Back-Step Blockchain</h4>,
     <p>We developped a first public available application based on Back-Step Blockchain technology as a service everybody can use. The app provides blocks of 1000 characters (byte) user can store. 
     Users must register to place blocks on the chain but their identity is not shown. Based on the invention with its low power consumption and high speed we could keep the <b>cost very low</b>. 
     We charge currently 2 Fee (with capital F) per block, equivalennt to 2 US cent. Each new registered user receives 500 Fee initial credit without cost.
     Everybody, even non registered people can read the chain without any cost. 
     There is a privacy mode where all data are strong encrypted. More about the encryption is explained at the Back-Step App. 

    This app will be continously enhanced and new features and functions will be added from time to time. 

    Back-Step App is for private and commercial organisations but it is currently <b> not a digital 
    currency.</b>  Instead, it is your private <b>electronic tresor</b>, your <b>event logbook</b>, your <b>supply chain </b>verifier, 
    your <b>Black-Box recorder</b>, your <b>exchange platform</b> for all kind of things, and <b>much more</b>....
    If you plan to install your own Back-Step Blockchain the Back-Step App is an ideal test case.
    </p>];
    
    const text_3 = [ <h4>Security by chip...</h4>,
    <p>The core of the Back-Step Blockchain is a generator inside a protected chip. The technology is similar to what you know from the chips in your credit card or your bank card.
    This provides the state of the art security. Details can be found in the patents . Beside the prototypes we will provide 3 different versions of the product. 
    The entry level Generator consits of a USB-C connected token  with a limit of 1000 blocks per second. Customers need their own server with network and storage connections. 
    Two higher performance products are under development one for 5000 blocks/s and one for above 10,000 blocks per second. In cases where higher speed is required multiple 
    transactions can be combined into a single block. Most cryptocurrencies use this technologies, because their blockchains are not fast enough.
    Because Harware can fail, it is recommended to build redundancy, preferred with 2 out of 3 or 3 out of 5 solutions. </p>];
    
    const text_4 = [ <h4>Do we need networks? </h4>,
    <p> Yes and No. In an application where a Back-Step Blockchain is integrated in a ID card or an passport only an application interface is needed, often wireless. 
        For server based applications the Back-Step Blockchain should be implemented in a redundancy mode. Here a high speed connection is preferrable. There might be also 
        application where the customer wants chains per country, region or even city. If the block input to the chain is provided by local network users or via internet 
        a network connection with the required performance is required. But there is absolutely no need of huge clusters with thousands of nodes for security purpose 
        as known from traditional networks.   
    </p> ];
    
    
    /*
    Recommendations from are welcome. I will implement also voting function, allowing you to decide 
    on certain subjects. The site will only be available in english language. Further more, the 
    data on this page are strict separated in public and private data. Therfor, a logon process is 
    required for access to the private data. 
    For security reasons I installed a dedicated web server for this site and registered the 
    domain name. Your data will never be seen by others. No spying, no analytics and no advertising.
    This is very much different to apps which run on your mobile smartphone and have access to all 
    your private data. I finance this WEB site with donations from my sponsors Blockchain Systems Pte Ltd 
    and Encryption Technology Pte Ltd'];
    */
    
    
    export class What_is_BSB extends React.Component  
    { 

        //------------------------------------------------------------------
        //  Constructor
        //------------------------------------------------------------------
        constructor(props) 
        {
          super(props);
          this.component= "";

        }  
        
    
        componentDidMount()
        {
          //console.log('Component did mount in About Us');
          this.prepare_for_rendering();
          this.forceUpdate();
    
        };
       
        // className={styles.backdrop}
        //-------------------------------------------------------------------------
        //  For Portrait
        //-------------------------------------------------------------------------   
        prepare_for_rendering()
        { 
          if (Context.portrait)
          {
            this.component = 
            <div className={styles.backdrop_portrait}>
                <Helmet>
                <title>What is Back-Step Blockchain</title>
                <meta name="description" content="Explains what this new invention is all about and their hge advantages." />
                <meta charset="utf-8" />
                </Helmet>

                <div className={styles.grid_header_portrait}>
                  <h3> What is a Back-Step Blockchain </h3>
                  </div>

                  <div className={styles.grid_item}> 
                  < Paper textLines = {text_1}/> 
                  </div>
    
                  <div className={styles.grid_item}>
                    <Paper  textLines = {text_2}/>
                  </div>
                  
                  <div className={styles.grid_item}>
                    <Paper  textLines = {text_3}/>
                  </div>
    
                  <div className={styles.grid_item}>
                    <Paper  textLines = {text_4}/>
                  </div>
    
            
          </div>
          }    
          else
          //-------------------------------------------------------------------------
          //  For Lanscape
          //-------------------------------------------------------------------------   
          {
            this.component = 
              <div className={styles.backdrop}>

                <Helmet>
                <title>What is Back-Step Blockchain</title>
                <meta name="description" content="Explains what this new invention is all about and their hge advantages." />
                <meta charset="utf-8" />
                </Helmet>

      
                  <div className={styles.grid_container}>

                  <div className={styles.grid_header}>
                    <h4> What is a Back-Step Blockchain </h4>
                    </div>

      
                    <div className={styles.grid_item}> 
                    < Newspaper textLines = {text_1}/> 
                    </div>
      
                    <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_2}/>
                    </div>
                    
                    <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_3}/>
                    </div>
      
                    <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_4}/>
                    </div>
      
                  </div>
            </div>
          }
        }
        
        render()
        {
          return this.component ;
        }
    }
    
    
    