
import React, { Component } from 'react';
import styles from './contact_form.module.css';
import { Buffer } from 'buffer';
import { Context } from '../services/context';
import pic_bird from '../pictures/Letterbird.png';
import { Helmet }           from 'react-helmet';

  //------------------------------------------------------------
  //
  //------------------------------------------------------------
class ContactForm extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
        message: '',
        formSubmitted: false, // New state to track form submission
        isSubmitButtonDisabled: true, // New state to control the submit button's disabled status
        emailFailed: false
        };
        console.log('contact form constructor');
    }

    componentDidMount() 
    {
        this.portrait = Context.portrait;
        this.prepare_for_rendering();
        this.forceUpdate();
    }



/*
    //------------------------------------------------------------
    //
    //------------------------------------------------------------
    handleChange = (event) => 
    {
        this.setState({ 
        [event.target.name]: event.target.value,
        isSubmitButtonDisabled: false, // Enable submit button when there's a change
        });
    }

    //------------------------------------------------------------
    //
    //------------------------------------------------------------
    handleSubmit = async (event) =>
    {
        event.preventDefault();

        const data = 'From ' + this.state.email + ': ' + this.state.message;
        const buffer = Buffer.from(data);
        //console.log('Form submitted:', buffer);
        this.setState({ emailFailed: false });

        if (this.socket) 
        {
       
            
            
                let packet = { func: "ADD_BLK", data: buffer };
                this.socket.emit('X7', JSON.stringify(packet)); // Emit the message to the server
                //console.log('Emitted: ', JSON.stringify(packet));
            
                // wait for a response befor cleaning

                   
            // Clear form fields and disable submit button after submission
            this.setState({
            message: '',
            formSubmitted: true,
            isSubmitButtonDisabled: true, // Disable submit button after form submission
            });

            // Optionally reset formSubmitted after showing the thank you message
            setTimeout(() => this.setState({ formSubmitted: false }), 3000);
        }
    }



    //------------------------------------------------------------
    //
    //------------------------------------------------------------
    renderThankYouMessage() 
    {
        if (this.state.formSubmitted) 
        {
            return <div><h5>Thank You for contacting Blockchain Systems. Have a nice day!</h5></div>;
        }
    }
*/


    //------------------------------------------------------------------
    //  Prepare the portrait output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 
      if (Context.portrait)
      {
        console.log('Contact portrait');

        this.component = 
          <div className={styles.backdrop_portrait}>

            <Helmet>
            <title>Contact to Blockchain Systems</title>
            <meta name="description" content="Shows that people with Trust.it registration can use the chain to communicate to Blockchain Systems" />
            <meta charset="utf-8" />
            </Helmet>

            <div className={styles.grid_header_portrait}>
              <h4>We need your feedback!</h4>
            </div>
    
            <div className={styles.grid_item_portrait}> <b>Users registered at Trust.it please use the app to contact us. </b> 
              <br/>
              Non-registered users contact us at info@backstepchain.com 
            </div>
    
            <div  className= {styles.grid_image_portrait}>
              <img src= {pic_bird} alt="Letterbird in contact page" />
              <p><small>Our beautifull bird knows the way to us. Don't forget the stamp. This is to feed the bird.</small></p>
            </div>

          </div>
      }
      else
      {
          //------------------------------------------------------------
          //  Prepare landscape output
          //------------------------------------------------------------
          console.log('Contact landscape');
          
          this.component = 
          <div className={styles.backdrop}>

            <Helmet>
            <title>Contact to Blockchain Systems</title>
            <meta name="description" content="Shows that people with Trust.it registration can use the chain to communicate to Blockchain Systems" />
            <meta charset="utf-8" />
            </Helmet>

            <div className={styles.grid_container}>
              <div className={styles.grid_item}> <b>Users registered at the Back-Step Blockchain app please use the app to contact us. </b> 
              <br/>
              Non-registered users contact us at info@backstepchain.com 
            </div>

            <div className = {styles.grid_image_portrait}>
               <img src= {pic_bird} alt="Letterbird in contact page" />
            </div>  
            <p><strong>Our beautifull Chirp messaging bird knows the way to us. Don't forget the stamp; this is to feed the bird.</strong></p>
           
          </div>
        </div>
      }
    }
       
  render() 
  {
    return this.component;
  }
}

export default ContactForm;
