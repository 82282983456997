import React     from 'react'; 
import styles    from './bsb_700.module.css';

import pic_coming_soon from '../pictures/We are working on it.webp';
import { Helmet }           from 'react-helmet';
  
  

export class BSB700 extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      //console.log('Component did mount in BSB700');
      this.prepare_for_rendering();
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 
      this.component = 
         <div className={styles.backdrop}>
            <Helmet>
            <title>BSB 700</title>
            <meta name="description" content="BSB 700 hardware is under development and a first HW prototype is ready." />
            <meta charset="utf-8" />
            </Helmet>


            <img src={pic_coming_soon} alt="picture coming soon" />
          
      </div>
    }
    
    render()
    {
      return this.component ;
    }
}  

