import React     from 'react'; 
import styles    from './bsb_100.module.css';
import Newspaper from '../components/Newspaper.jsx';
import Paper     from '../components/Paper.jsx';
import {Context} from '../services/context.js';

import pic_trust_it_1 from '../pictures/Luxery shoes.webp'; 
import pic_trust_it_2 from '../pictures/Palace Hotel San Francisco.webp'; 
import pic_trust_it_3 from '../pictures/Airplane Maintenence.webp'; 
import pic_trust_it_4 from '../pictures/Petty Cash.webp'; 
import pic_BSB100_hw  from  '../pictures/BSB 100 Hardware.png';
import { Helmet }           from 'react-helmet';

const images =[
  {title:'Pic 1', picture: pic_trust_it_1, index:1}, 
  {title:'Pic 2', picture: pic_trust_it_2, index:2},
  {title:'Pic 3', picture: pic_trust_it_3, index:3},
  {title:'Pic 4', picture: pic_trust_it_4, index:4}, ] ;


const text_BSB100_1 = [
    <h4 key="header1">Small and Powerful</h4>,

    <p key="paragraph1">
     This product is currently under development. Prototype and the first customer lot are expected later this year.
    </p>,

    <h4 key="header2">Functions</h4>,
    
    <p key="paragraph2">
      BSB-100 is the central hardware of a Back-Step Blockchain for small to medium load. It receives power, data and 
      commands via a USB-C physical interface. The protocol used (USB-2 or USB-3) depends on the negotiation with the host computer. 
      The software on the host computer needs a driver for the USB protocol. The host computer can run on any OS, when the USB required commands are supported.
      Above the driver software is normally a set of functions highly depend on the application. In many cases is a Lan interface required to connect  
      the services to the place of use. This can be a company network or Internet. Often is creating blocks and placing them on the chain separated from the pure reading. 
      For example, everybody on the WEB may be reading the blocks, but only the newspaper agency is able to create new entries when the articles are verified to be not faked.  
    </p>,
    
    <p key="paragraph3"> Often you need a block management layer above the USB driver, a storage manager layer writing the processed blocks onto the media and a communication layer
        receiving and sending blocks to their place of use. In some cases it is possible to integrate additional business logic directly into the blockchain server. 
        In some cases the SW processing takes much longer than the processing in the BSB. The required performance must be considered when the server is designed.
        If NodeJS or Node Express servers are used the single processing thread needs consideration. For computation intensive tasks worker threads are recommended 
        to use the multiple CPU cores.   
    </p>,

    <h4 key="header2">Management</h4>,
    
    <p key="paragraph4">
        BSB-100 provides a set of USB commands used for management functions and status reports. These functions are separated from the normal operational functions.
        The product provides an internal state machine that decides which function is allowed at which state. Functions like adding block count are privileged.  
        and needs to halt the normal operation for a short moment. 

    </p>,

  ];
  
  const text_BSB100_2 = [

    <h4 key="header5">Prelimitary Technical Data </h4>,
    
      <ul>
        <li key="mode1"><strong>Performance:</strong> max. 1000 blocks per second.</li>
        <li key="mode2"><strong>Power consumption:</strong> ~8 Watt at full load.</li>
        <li key="mode3"><strong>Startup time:</strong> 10 seconds after power on.</li>
        <li key="mode4"><strong>Pipelining: </strong> serial operation, block 1 processed before block 2 can be send to BSB-100. </li>
      </ul>

   
   // <img src={pic_BSB100_hw} alt="picture BSB100" />,
   // <p>The diagram shows the principle architecture of the BSB-100 hardware</p>

  ];
  


export class BSB100 extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      //('Component did mount in TrustIt');
      this.prepare_for_rendering();
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 

      if (Context.portrait)
      {
          console.log('BSB100 portrait');
          this.component =              
    
            <div className={styles.backdrop_portrait}>
 
               <Helmet>
                  <title>BSB 100</title>
                  <meta name="description" content="Explains BSB 100 hardware with Back-Step blockchain inbuilt, its high speed and low power consumption. Shows picture" />
                  <meta charset="utf-8" />
                </Helmet>

              <div className={styles.grid_header_portrait}>
                  <h4> BSB-100 Small and Powerfull </h4>
              </div>

              <div className={styles.grid_item}> 
                  < Paper textLines = {text_BSB100_1}/> 
              </div>

              <div  className={styles.grid_item}>
                  <Paper  textLines = {text_BSB100_2}/>
                    <div className={styles.grid_image}>
                      <img src={pic_BSB100_hw} alt="picture BSB100" />
                      <p>The diagram shows the principle architecture of the BSB-100 hardware</p> 
                   </div>
              </div>
              
          </div>

      }
      else
      {

        this.component = 
          <div className={styles.backdrop}>

                <Helmet>
                  <title>BSB 100</title>
                  <meta name="description" content="Explains BSB 100 hardware with Back-Step blockchain inbuilt, its high speed and low power consumption. Shows picture" />
                  <meta charset="utf-8" />
                </Helmet>


              <div className={styles.grid_container}>
                  <div className={styles.grid_header}>
                    <h4> BSB-100 Small and Powerfull </h4>
                </div>

                <div className={styles.grid_item}> 
                < Newspaper textLines = {text_BSB100_1}/> 
                </div>

                <div className={styles.grid_item}>
                  <Paper  textLines = {text_BSB100_2}/>

                    <div class_name ={styles.grid_image}>
                      <img src={pic_BSB100_hw} alt="picture BSB100" />
                     <p>The diagram shows the principle architecture of the BSB-100 hardware</p> 
                    </div>
                </div>
              </div>
        </div>
      
      }
    }
    
    render()
    {
      return this.component ;
    }
}  

