// import {useEffect} from 'react';


export default function Footer()
{
  const MoveTop = () =>
  {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <div className='own-footer' onClick={MoveTop}>
        (c) Copyright Georg Krause, 2023 - Impressum at <strong>About</strong> 
        <span> - Click to Top</span>
    </div>
  )
}

