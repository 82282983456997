import React        from 'react'; 
import styles       from'./money.module.css';
import Newspaper    from '../components/Newspaper.jsx';
import Paper        from '../components/Paper.jsx';
import pic_museum   from '../pictures/Bank notes in Museum.webp';
import {Context}    from '../services/context.js';
import { Helmet }           from 'react-helmet';


/*
const text_1  = [<h4>The challenges of electronic currencies</h4> ,

<p>There are plenty of currencies available on the web. Most are not currencies, but products, where supply and demand dictates the price. 
  The illusion is created that you can get rich the easy way. With little supply at the beginning and some marketing effort the the engine gets running and 
  it may make some investors happy. Then starts the adicction effect. Most of the humans want more, more, more. It is obvious that this cannot work. 
  As a side effect of the volatility of most Cryptocurrencies they cannot used for daily life. Try to buy a baguette or a croisant with Bitcoin. Good look. 
  Most shops learned about the risk and do not accept "Cryptocurrencies" for daily life. It is easy to make these kind on "Cryptocurrencies" work on the Back-Step Blockchain.
  We show in another article how we believe a Crytocurrency based on Back-Step Blockchain could be designed and solve these issues, becomming a benefitial tool for everybodies life.
</p>];


const text_3 = [ <h4>Crime suport</h4>,
<p> The effect that most "Cryptocurrencies" keep the owner anonymous make them a perfect tool for crimes. This is widely known, but very few authorities took action. 
I believe China is here a positive example. </p>, <p>Fiat money is not annonymous, each banknote has a serial number which can be machine read and traced. 
  The relation to a holder is not obvious, but can be reconstructed using modern AI technologies. Fiat money is mostly restricted to the terretory where the 
  money is legal tender. In a criminal activity it is complicated to transfer money international. These are some reasons why criminals love "Cryptocurrencies". 
  It is time to stop such activities.</p>];


const text_4 = [ <h4>A new approach to electronic money</h4>,
<p> Back-Step Blockchain opens new possibilities for Cryptocurrencies. What you read here are ideas and considderations how a electronic currency 
  could be designed based on our fast Back-Step Blockchain. Countries, experts and interesteded designers are welcome to contact us to exchange ideas.
  Current blockchains suffer from their low speed and make it impossible to represent a payment (transaction) in a single block. 
  To be successful a Cryptocurrency must provide advantages for all involved parties, except for people with criminal intentions.</p>, 
  <p> First it must be designed for the everyday users. Buying a coffee at Starbucks must be easy and faster then using a credit card. </p>,
  
  <p> For the shop the crdit card fee is replaced by a Back-Step Blockchain FEE, which is much lower. The shop saves money and time.</p>, 
  <p>  Users may have multiple currencies on one chain and can use the same chain in the participating countries. The blockchain gives them real time overview 
  of all spendings and receivings. Dayly money management is simpler. No need to change manually a $50 bill in 5 x $10 bills. No need to queue at ATM machines 
  or bank counters. The difference between banknotes and coins does not exist anymore. 
  Each user carries his own money in his own wallet chain. If a average user has 80 transactions on a chain per day it requires 40 Megabyte storage capacity per year. 
  All required chain calculations are performed on the phone.</p>, 
  <p> <b>TRUST & TRASPARENCY </b></p>,
  <p> Each piese of money has its unique serial number and is signed by the national authority. A user can only spend money which exists on his own blockchain 
  and is not previously spend. Beside it is technically prevented, manipulating the chain would block the user and he cannot receive or spend further money. 
  The Back-Step Blockchain for such a Cryptocurrency is only partial public readable. Authorities can use the chain to detect money laundering attempts, 
  which is in everybodies interest. The user identity is stored in block 0 of the Back-Step Blockchain. Stealing such a phone is easy detected and 
  the illegal use of the money will be prevented. The Back-Step Blockchain will maintain the date when a piece of money is received. A payment process uses oldest money pieces 
  first and combines money pieces such that no or a minimum return tranfesr are required. 
  A typical receiver, lets say in a shop will calculate the return exchange transfer automatically. </p>, 
  
  <p>
  The government maintains an exchange service where users can slit or combine money pieces. The money pieces itself do not age, there is no process needed to fish out 
  old damaged bank notes. The system can be implemented step by step with an initial cap that is increased when the system
  proves success. Countries can than save the coin mining process and put the bank notes in the history museum. 
</p> ];
*/

const text_1  = [<h4>The Challenges of Electronic Currencies</h4>,
<p>
Despite the proliferation of digital currencies online, many are not true currencies but rather products governed by supply and demand. This dynamic fosters 
the illusion of easy wealth, spurred on by limited initial supply and aggressive marketing. Such strategies can indeed generate temporary success and 
satisfaction among early investors, but they also introduce a cycle of addiction, as the desire for more becomes insatiable. This is fundamentally unsustainable. 
Additionally, the extreme volatility of many cryptocurrencies renders them impractical for everyday transactions. For instance, the idea of buying everyday items 
like a baguette or a croissant with Bitcoin is fraught with difficulties, leading most merchants to avoid cryptocurrencies for daily transactions. 
However, integrating such digital currencies into the Back-Step Blockchain could offer a solution. We explore in another article how a cryptocurrency 
designed on the Back-Step Blockchain could address these issues, potentially transforming it into a beneficial tool for daily life.
</p>];

const text_3  = [<h4>Crime Support Through Anonymity</h4>,
<p>
The anonymity offered by many cryptocurrencies has unfortunately made them a preferred tool for illicit activities. While this issue is widely recognized, 
only a handful of jurisdictions, like China, have taken decisive action against it. Unlike cryptocurrencies, fiat currencies are not anonymous; 
each banknote carries a serial number that can be traced, albeit with some effort, using modern AI technologies. 
Furthermore, fiat money's utility is generally confined to its country of origin, complicating its use in cross-border crimes. 
These factors contribute to the criminal preference for cryptocurrencies. It is imperative that steps are taken to curtail these abuses.
</p>];



const text_4  = [<h4>A New Approach to Electronic Money</h4>,
<p>
The Back-Step Blockchain introduces groundbreaking possibilities for cryptocurrencies. Here, we outline ideas and considerations for designing an electronic 
currency leveraging our advanced Back-Step Blockchain technology. We invite nations, experts, and forward-thinking designers to collaborate and share insights 
on this innovative venture. Traditional blockchains are hampered by slow transaction speeds, preventing the real-time processing of payments. 
A successful cryptocurrency must offer tangible benefits to all users—excluding those with illicit motives.
</p>,

<p>
Primarily, it should cater to everyday transactions, making purchasing as simple and quicker than using traditional credit cards. For merchants, the transaction 
fees associated with credit cards are replaced by significantly lower Back-Step Blockchain fees, leading to savings in both money and time. Users benefit 
from the ability to manage multiple currencies on a single chain, simplifying daily financial management without the need for physical currency exchanges or 
ATM visits. An average user’s annual transactions would require just 40 megabytes of storage, with all necessary computations performed on their smartphone.
</p>,

<p>
<b>Trust & Transparency</b>
</p>,

<p>
Each unit of currency is uniquely numbered and authenticated by the issuing authority, ensuring users can only spend what they possess and have not already spent. 
Manipulation of the chain is not only technically thwarted but would also result in the user being blocked from further transactions. The Back-Step 
Blockchain's design includes partially public readability, allowing authorities to monitor for and prevent money laundering, thus serving the public interest. 
User identities are securely stored, and anti-theft measures ensure that stolen funds cannot be illicitly used. Additionally, the system prioritizes 
the spending of older currency units first, streamlining transactions and minimizing the need for change. Government-operated services facilitate the 
merging or splitting of currency units, obviating the need to replace worn or damaged notes. This system allows for incremental implementation, with scalability 
following proven success, paving the way for a future free from the burden of minting physical currency.
</p>]

export class Money extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);
    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {

        this.prepare_for_rendering();
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});

    }



    //--------------------------------------------------------------------------
    // Prepare for rendering
    //--------------------------------------------------------------------------
    prepare_for_rendering()
    { 
      if (Context.portrait)
      {
            this.component = 
               <div className={styles.backdrop.portrait}>
   
                    <Helmet>
                     <title>Money Apps with Back-Step Blockchain</title>
                      <meta name="description" content="Explains new opportunities for financial applications and better Crypto money." />
                      <meta charset="utf-8" />
                    </Helmet>

  
                <div className={styles.grid_header_portrait}>
                <h4>Money turns the world arround</h4>
                </div>

                <div className={styles.grid_item}> 
                 < Paper textLines = {text_1}/> 
                </div>
  
                
                <div className={styles.grid_item}>
                  <Paper  textLines = {text_3}/>
                </div>
  
                <div className={styles.grid_item}>
                  <Paper  textLines = {text_4}/>
                </div>

                <div className={styles.grid_item}>
                  <p> </p>
                  <img src={pic_museum} width='250' alt="pic_museum" />
                  <p>Parents show their kids how their grandparents paid for goods.</p>

                </div>
  
              </div>



      }
      else
      {
        this.component = 
           <div className={styles.backdrop}>
                <Helmet>
                  <title>Money Apps with Back-Step Blockchain</title>
                  <meta name="description" content="Explains new opportunities for financial applications and better Crypto money." />
                  <meta charset="utf-8" />
                </Helmet>

  
              <div className={styles.grid_container}>
  
                <div className={styles.grid_header}>
                <h4>Opportunities with Electronic Money</h4>
                </div>

                <div className={styles.grid_item}> 
                 < Newspaper textLines = {text_1}/> 
                </div>
  
                
                <div className={styles.grid_item}>
                 <Newspaper  textLines = {text_3}/>
                </div>
  
                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_4}/>
                </div>

                <div className={styles.grid_item}>
                  <p> </p>
                  <img src={pic_museum} width='250' alt="pic_museum" />
                  <p>Parents show their kids how their grandparents paid for goods.</p>

                </div>
  

              </div>
        </div>
      }
    }  
    render()
    {
        return this.component;
    }

}