
import React     from 'react'; 
import Container from 'react-bootstrap/Container';
//import '../App.css';
import {What_is_BSB} from './what_is_BSB.jsx';
import {UseCases}    from './use_cases.jsx';
import {Money}       from './money.jsx';
import {HowItWorks}  from './how_it_works.jsx';
import styles        from './overview.module.css';
import { Helmet }           from 'react-helmet';


import MyCard     from '../components/MyCardV2';

import pic_home_1 from '../pictures/Blockchain box with people.png';
import pic_home_2 from '../pictures/what_can_it_do.jpg';
import pic_home_4 from '../pictures/Currency.webp'; 
import pic_home_3 from '../pictures/University.webp';
import pic_home_5 from '../pictures/OldOffice.png';
import pic_home_6 from '../pictures/API.webp';

// import { Alert } from "react-bootstrap";

const images =[
  {title:'Pic 1', picture: pic_home_1, index:1},
  {title:'Pic 2', picture: pic_home_2, index:2},
  {title:'Pic 3', picture: pic_home_3, index:3}, 
  {title:'Pic 4', picture: pic_home_4, index:4},
  {title:'Pic 5', picture: pic_home_5, index:5},
  {title:'Pic 6', picture: pic_home_6, index:6}] ;

const title_home_1 ="Back-Step Blockchain - What is it?";
const text_home_1  =[<p>Back-Step Blockchain is a new underlying chain technology for existing and new blockchain applications. It has many advantages over 
                     traditional blockchains.</p>, 
                     
                    <p>
                    At the core is the Back-Step Generator which produces the values to secure the chain. The Back-Step and
                    Seal values are directly feed into the block when the block is processed.  </p>, <p>For practical and speed reasons Back-Step Generator 
                    and the block processor are integrated into the same hardware.</p>];


const title_home_2 = "A few use cases";
const text_home_2  = `Assist private life, support your business from small to multinational, organize governments and many more application fields.`


const old_text_2 =`You are invited to use this Back-Step Blockchain app by yourself and experiance all the new opportunities. 
The first 10.000 users will receive credit for 1000 free blocks on the chain. No Ether, no Satoshi and no bank cheques.  
The chain has an initial capacity of 1 Giga Blocks and will grow when required.
Each block can store around 1040 byte payload. This application uses text as user payload. 

Be aware that the chain is a beta version and there is a small risk of failure. We also may halt the chain for a short time when we implement new features.
It is recommended that you backup the chain on your own computer. The Back-Step Blockchain behind this WEB page is for your use. It is still expirimental and it will be improved over time. 
The chain starts on a single server and will be extended to 3 out of 5 redundancy during our test phase.

Here are some additional information for the technical interested audience: Each block contains additional space for importent metadata like a time stamp 
or a block serial number. These metadata are created when a block is secured and placed on the chain. 

We reserved 2 terabyte of blockchain space on the storage system for our users. For comparison, the file size of the most popular blockchain is ~500 GB as of end of 2023.
The Back-Step blocks are also larger than on most other chains wiyth a size of 1232 byte for each block. About 1040 byte of that are available as payload. This gives you more space for your application records  
Thisand allows other chains to become children of a Back-Step Blockchain like you know it from Virtual Machines on computer systems. Lots of opportunities for our collegue developers.`;

const title_home_33 ="How to secure larger objects like pictures, videos, music, computer codes, and NFTs?";
const text_home_33  =`These objects are too big for the payload field of the chain. The solution is to use a content and a header file. 
Bothe are publically accessable. The content file is pointed to by the payload field of your Back-Step Blockchain. This can be in the simplest way a URL 
or just a name of a local or cloud based file. The content file is hashed with the same function used in the Back-Step Blockchain itself.

You can see this as a 'Fingerprint' of your file. The fingerprint is stored in the payload field of your Back-Step Blockchain. For digital assets 
you add your identity proof in the same payload and you have a strong proof that the digital assets are yours. Everybody can read and verify it but nobody can fake it.
The timestamp of the block shows who was first if others try to copy the digital asset and claimj it is thers.

These technique can also be used to verify the original source of news articles as protection against fake news. Great for all news agencies. An absolute must 
againt artificial AI fakes.

Whenever the objects are verified they are hashed with the same algorithm used in the Back-Step Blockchain and this hash must match the hash stored in the 
payload of you Back-Step Blockchain. Users can verify by themself.`;


const text_home_55  =`Great ideas need to be protected, especially for a small company. Patents are expensive but they help to make Back-Step Blockchain a success. 
The most important part are the users with all their ideas what they can achieve this blockchain. Back-Step Blockchain patent is granted in Europa as a Europen 
patent with multiple countries included, in Japan, Singapore, and USA. Many othe countries like China, India, Korea, Vietnam, Israel and UK are pending. 
It means the examination proces is not yet completed. Different countries have different workload`;


const title_home_3 ="Back-Step Blockchain is different - How it works";
const text_home_3  =`Here we explain how it works in an overview for people with some blockchain background. `;

const title_home_4 ="A trustful base for digital currencies.";
const text_home_4  =`Back-Step Blockchain opens the door for a new class of cryptocurrencies. Trustfull, stable and fast. Exactly what you need for your daily life.`;


const text_home_333  =`All kind of cryptocurrencies can be based on Back-Step Blockchain. A widely accepted currency for daily life must be stable, not a subject for speculation. 
Back-Step Blockchain provides all functions required to design currencies that avoid money laundering and criminal financial activities. Easch banknote in your wallet has a serial number.
The same feature is available with Back-Step Blockchain based currencies.
If someone wants to convert an existing currency like "Bitcoin" to the Back-Step platform, it is easy. It will save gigawatts of electricity which can be better used for AI.`;

const title_home_5 = "AI and Back-Step Blockchain!";
const text_home_5  = [<p>Times changed! Artificial General Intelligence creates content that looks very realistic. 
Back-Step Blockchain helps to detect what is human made and what is AI made.
</p>, 

<p>
We used DALL-E for some illustrations and ChatGPT-4 to help with a good english.</p>];

const title_home_6 = "Learn to create your blockchain apps with the API.";
const text_home_6  = [<p>You know your customers better than enybody else. Create apps for them they couldn't belive they were possible. 
We help you learn the Back-Step Blockchain and then you change the market!
</p>, 
  
<p>
More details follow soon...</p>];


//------------------------------------------------
//  Class
//------------------------------------------------
export class Overview extends React.Component  
{

  //------------------------------------------------
  //  Constructor
  //------------------------------------------------
  constructor(props) 
  {    
    super(props);
    //console.log('Constructor', props.form);
    this.mainform           = ''
    this.selected_form      = props.form;
    this.detail_card        =  this.detail_card.bind(this);
  }

  //------------------------------------------------
  //  Component Mount
  //------------------------------------------------
  componentDidMount()
  {
      this.prepare_for_rendering();
      this.forceUpdate();
  }
  // 👇️ scroll to top on page load

   //className = {styles.backdrop}

  //-----------------------------------------------
  //  Methods to show the full details of a topic
  //----------------------------------------------- 
  detail_card(select)
  {
    //console.log('detail_card', select);
    this.selected_form = select;
    this.forceUpdate();
  }
   

  //------------------------------------------------------
  //  Return the render data
  //------------------------------------------------------
  prepare_for_rendering()
  {
    //console.log('prepare_for_rendering' );

    this.mainform = 
    <div className={styles.backdrop}>                  
            <Helmet>
            <title>Overview showing many aspects of the invention</title>
            <meta name="description" content="Short description of the details explained in own pages." />
            <meta charset="utf-8" />
          </Helmet>

      <Container className={styles.grid_container}>
        <MyCard 
          title={title_home_1}
          img={pic_home_1} 
          text={text_home_1}
          btn="See more..."
          detail={()=>this.detail_card(1)}>
        </MyCard> 

        <MyCard 
          title={title_home_2}
          img={pic_home_2} 
          text={text_home_2}
          btn="See more..."
          detail={()=>this.detail_card(2)}>

        </MyCard>  
      </Container>

      <Container className={styles.grid_container}>                   
        <MyCard 
          title={title_home_3}
          img={pic_home_3} 
          text={text_home_3}
          btn="See more..."
          detail={()=>this.detail_card(3)}>            
        </MyCard>  

        <MyCard 
          title={title_home_4}
          img={pic_home_4} 
          text={text_home_4}
          btn="See more..."
          detail={()=>this.detail_card(4)}>  
        </MyCard>  
      </Container>

      <Container className={styles.grid_container}>                   
        <MyCard 
          title={title_home_5}
          img={pic_home_5} 
          text={text_home_5}
          btn="See more..."
          detail={()=>this.detail_card(5)}>            
        </MyCard>  

        <MyCard 
          title={title_home_6}
          img={pic_home_6} 
          text={text_home_6}
          btn="See more..."
          detail={()=>this.detail_card(6)}>            
        </MyCard>  
      </Container>       
    </div>
  }

 
  render() 
  {
    switch (this.selected_form)
    {
      case 1:
        this.component = <div><What_is_BSB/></div>;
        break;

        case 2:  
        this.component = <div><UseCases/></div>;
        break;

        case 3:  
        this.component = <div><HowItWorks/></div>;
        break;

        case 4:  
        this.component = <div><Money/></div>;
        break;

        case 5:  
        this.component = this.mainform;
        break;

        case 6:  
        this.component = this.mainform;
        break;

      default:
        this.component = this.mainform;
    }

    //console.log('Render', this.component);
    this.selected_form = this.mainform;
    return  this.component ;
  }
}
