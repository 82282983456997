import React        from 'react'; 
import styles       from'./investors.module.css';
import Newspaper    from '../components/Newspaper.jsx';
import Paper         from '../components/Paper.jsx';
import {Context}     from '../services/context.js';
import pic_museum    from '../pictures/Bank notes in Museum.webp';
import pic_digging_1 from '../pictures/One digging for gold.webp';
import pic_digging_2 from '../pictures/Professional digging-1.webp';
import pic_digging_3 from '../pictures/Professional digging-2.webp';
import { Helmet }           from 'react-helmet';

const text_1 = [ <h4>Growth through Trust</h4>,
  <p>Back-Step Blockchain emerges as a beacon of trust in an era brimming with uncertainties and a burgeoning amount of falsified and manipulated information. 
  Trust, now more than ever, is paramount. Blockchain technology is heralded as the quintessential means to instill unwavering trust. 
  It's the foundation upon which all cryptocurrencies are built. 
  Yet, beyond cryptocurrencies and smart contracts, the application of blockchain has been relatively limited, leading to a more tempered growth trajectory.</p>,
  <h4>Unleashing Potential</h4>,
  <p>The Back-Step Blockchain revolutionizes the landscape, dismantling previous limitations on use cases and growth. It pivots on three fundamental pillars:
     Speed, Efficiency, and Connectivity. These core attributes redefine the boundaries of blockchain's applicability and set a new benchmark for its expansion.</p>
];

const text_2 = [ <h4>Accelerating Market Evolution</h4>,
  <p>Blockchain Systems Pte. Ltd., spearheaded by the inventor of Back-Step Blockchain, is privately held, with initial funding from its affiliate, 
  Encryption Technology. While organic growth remains a viable path, it is, in light of the expansive market possibilities, a less favorable one. 
  Back-Step Blockchain stands on the cusp of market revolution, demanding more than just incremental advancements.</p>, 
  <p> We seek substantial investment 
  exceeding 4 million USD and/or strategic partnerships with leading market forces. Interested entities are encouraged to reach out to the founder at 
  georg.krause@blockchainsystems.sg.</p>
]

const text_3 = [<strong>Single man digging for gold</strong>,
                <img src={pic_digging_1} width='250' alt="digging for gold" />]

const text_4 = [ <strong>Professional team digging for much more gold.</strong>,
                <img src={pic_digging_2} width='250' alt="team digging for much more gold" />]



export class Investors extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);
    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {

        this.prepare_for_rendering();
        this.forceUpdate();
        window.scrollTo({top:0, left: 0, behavious: 'smooth'});

    }



    //--------------------------------------------------------------------------
    // Prepare for rendering
    //--------------------------------------------------------------------------
    prepare_for_rendering()
    { 
        if (Context.portrait)
        {
    
          this.component = 
            <div className={styles.backdrop_portrait}>

                    <Helmet>
                     <title>Investors</title>
                      <meta name="description" content="Why investing in Back-Step Blockchain is wise and makes the products grow fast. Pictures included." />
                      <meta charset="utf-8" />
                    </Helmet>

    
                  <div className={styles.grid_header_portrait}>
                    <h4>Opportunities for You and US</h4>
                  </div>

                  <div className={styles.grid_item}> 
                  < Paper textLines = {text_1}/> 
                  </div>
  
                  
                  <div className={styles.grid_item}>
                    <Paper  textLines = {text_2}/>
                  </div>


                  <div  className={styles.grid_item}>
                    <Paper textLines = {text_3}/>
                  </div>
            
                  <div  className={styles.grid_item}>
                    <Paper textLines = {text_4}/>
                  </div>

                  <div style = {{ color: 'whitesmoke', textAlign: 'center'}}>
                    <strong>Support me, building the winning team!</strong>
                  </div>

              </div>
          }
          else
          {
            this.component = 
              <div className={styles.backdrop}>
                     <Helmet>
                     <title>Investors</title>
                      <meta name="description" content="Why investing in Back-Step Blockchain is wise and makes the products grow fast. Pictures included." />
                      <meta charset="utf-8" />
                    </Helmet>
     
              <div className={styles.grid_container}>

                <div className={styles.grid_header}>
                  <h4>Opportunities with Back-Step Blockchain</h4>
                </div>

                <div className={styles.grid_item}> 
                < Paper textLines = {text_1}/> 
                </div>

                
                <div className={styles.grid_item}>
                  <Paper  textLines = {text_2}/>
                </div>


                <div  className={styles.grid_item}>
                  <p>Single man digging for gold.</p>
                  <img src={pic_digging_1} width='250' alt="digging for gold" />
                </div>
          
                <div  className={styles.grid_item}>
                  <p>Professional team digging for much more gold.</p>
                  <img src={pic_digging_2} width='250' alt="team digging for much more gold" />
                </div>
              </div>
            </div>
          }
      }

    render()
    {
        return this.component;
    }

}