import React, { useState }  from "react";
import {Card}               from "react-bootstrap";
import { Button }           from "react-bootstrap";

import '../App.css';

//--------------------------------------------------------
//  Component definition'
//
//--------------------------------------------------------
export default function MyCardPortrait(props) 
{

  const text = props.text;
  let component;
  let maxHeight = 600;
 
 const [isReadMore, setIsReadMore] = useState(true);  
 const [pic, setPic] = useState(
    {
      small_size: true,
      width:      '100%'
    } );
    

  
  //---------------------------------------------
  //  W3 need a callback to the caller of the card
  //---------------------------------------------
 
  
  //-------------------------------------------------
  //  prepare what to render
  //-------------------------------------------------

    component =
    <Card style={{ color:'white', boxShadow: '5px 5px 5px black', fontSize: '2vh',  backgroundColor: "#B09070", textAlign: "justify", padding:2, margin: 1, height: "maxHeight" }}>
        <Card.Header style={{color:'black', fontSize: '3vh', fontWeight:'bold' }}>{props.title}</Card.Header>
        <Card.Img src={props.img} variant="top" alt="Card" width={pic.width}  onClick={props.detail} />
        <Card.Body >
            <Card.Text >{ text}  </Card.Text>
          </Card.Body>
        <Card.Footer className='card-footer'>
         <Button variant="info" size="sm" onClick={props.detail}> See more...</Button>
        </Card.Footer>
    </Card>
     
    return component
}