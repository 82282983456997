import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import '../App.css';
import styles     from './home.module.css';
import pic_home   from '../pictures/Backdrop Image.jpg';
import pic_home2   from '../pictures/Backdrop Image Portrait2.jpg';
import pic_home_portrait from '../pictures/Backdrop Image Portrait.jpg';
import pic_home_portrait2 from '../pictures/Backdrop Image Portrait2.jpg';
import {Context} from '../services/context.js'
import { Helmet }           from 'react-helmet';


export default function Home(props) 
{
   //------------------------------------------------------
  //  Return the render data
  //------------------------------------------------------

  if (Context.portrait)
    {
      return (
   
        <div className={styles.backdrop}>
            <Helmet>
            <title>Home page</title>
            <meta name="description" content="Shows Singapore the location where Back-Step Blockchain was invented." />
            <meta charset="utf-8" />
            </Helmet>
         {/*} <p className={styles.white_text}> menu </p>*/}
          <img src= {pic_home_portrait} alt="Picture of Singapore" />
        </div>
      );
    }
    else
    {

    return (
    
      <div className={styles.backdrop}>
            <Helmet>
            <title>Home page</title>
            <meta name="description" content="Shows Singapore the location where Back-Step Blockchain was invented." />
            <meta charset="utf-8" />
            </Helmet>

        <img src= {pic_home} alt="Entry picture of Singapore" />
      </div>
    );
  }

}

  