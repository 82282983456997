import React              from 'react'; 
import styles             from './products.module.css';
import {Context}          from '../services/context.js';

//import '../App.css';
import MyCard             from '../components/MyCardV2.js';
import MyCardPortrait     from '../components/MyCardPortrait.js';
import {TrustIt}          from './trust_it.jsx';
import {BSB100}           from './bsb_100.jsx';
import {BSB300}           from './bsb_300.jsx'
import {BSB700}           from './bsb_700.jsx';


import pic_product_2 from '../pictures/Demo Screen.png';
import pic_product_3 from '../pictures/Small Trust.it.webp'; 
import pic_product_4 from '../pictures/BSB 300.png';
import pic_product_5 from '../pictures/003.jpg';
import { Helmet }           from 'react-helmet';

// import { Alert } from "react-bootstrap";

const images =[

  {title:'Pic 2', picture: pic_product_2, index:2},
  {title:'Pic 3', picture: pic_product_3, index:3}, 
  {title:'Pic 4', picture: pic_product_4, index:4},
  {title:'Pic 5', picture: pic_product_5, index:5}
] ;

  
const title_product_2 = <p> Trust.it - Chirp on Your Chain </p>;
const text_product_2  = [<p>Soon on-line on the WEB, currently in beta testing</p>,
<p>Trust.it is the application on the Back-Step Blockchain. Chirp and keep your secrets. It is available to everybody on the internet.
</p>];



const title_product_3 = "Entry Back-Step Generator BSB 100";
const text_product_3  = [ <p>For small businesses running their own blockchain with max. 1000 blocks/second. </p>];

const title_product_4 = "BSB 300 Full scale business version.";
const text_product_4  =`For bussiness applications with heigh transaction rates. Ideal to replace and expand existing Cryptocurrencies.`;

const title_product_5 = "High Speed AI & Currency Processor.";
const text_product_5  = `In prototype state. High speed block processing for challenging applications like real time Credit Card transactions during peak times or AI transactions. 
The product realized a pass through architecture with seperate processors for raw block input and for processed blocks ready for storage.`;


//------------------------------------------------
//  Class
//------------------------------------------------
export class Products extends React.Component  
{

  //------------------------------------------------
  //  Constructor
  //------------------------------------------------
  constructor(props) 
  {    
    super(props);
    this.mainform           = ''
    this.selected_form      = props.form;
    this.detail_card        = this.detail_card.bind(this);
   }

  //------------------------------------------------
  //  Component Mount
  //------------------------------------------------
  componentDidMount(props)
  {
    console.log('Cmponent did mount in product', this.props);
      this.prepare_for_rendering();
      this.forceUpdate();
  }

  //------------------------------------------------ 
  //
  //------------------------------------------------
  componentDidUpdate(prevProps) 
  {
    if (prevProps.version !== this.props.version) 
    {
  
      // You can do something here when version changes
    }
  }

  //-----------------------------------------------
  //  Methods to show the full details of a topic
  //----------------------------------------------- 
  detail_card(select)
   {
    console.log('detail_card', select);

    this.selected_form = select;
    //this.prepare_for_rendering();
    this.forceUpdate();
   }
   

  //------------------------------------------------------
  //  Return the render data
  //------------------------------------------------------
  prepare_for_rendering()
  {
    if (Context.portrait)
    {
        console.log('Portrait products');

        this.mainform = 
        <div>
           <Helmet>
            <title>Product Overview</title>
            <meta name="description" content="Shows products available and under development. Each product has its own page" />
            <meta charset="utf-8" />
          </Helmet>

          <div className={styles.backdrop_portrait}>

            <div className={styles.grid_header_portrait}>
                <h4>It's all about Hardware</h4> 
              </div>

            <div className={styles.grid_item}>
                <MyCardPortrait 
                  key ={1}
                  title={title_product_2}
                  img={pic_product_2} 
                  text={text_product_2}
                  btn="See more..."
                  detail={()=>this.detail_card(2)}>
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={2}
                  title={title_product_3}
                  img={pic_product_3} 
                  text={text_product_3}
                  btn="Read more..."
                  detail={()=>this.detail_card(3)}>            
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={3} 
                  title={title_product_4}
                  img={pic_product_4} 
                  text={text_product_4}
                  btn="Read more..."
                  detail={()=>this.detail_card(4)}>  
                </MyCardPortrait>  
              </div>

              <div className={styles.grid_item}> 
                <MyCardPortrait 
                  key ={4}
                  title={title_product_5}
                  img={pic_product_5} 
                  text={text_product_5}
                  btn="Read more..."
                  detail={()=>this.detail_card(5)}>            
                </MyCardPortrait>  
              </div>
            </div>
            </div>
    }
    else
    {

        this.mainform = 
        <div className={styles.backdrop}>
           <Helmet>
            <title>Product Overview</title>
            <meta name="description" content="Shows products available and under development. Each product has its own page" />
            <meta charset="utf-8" />
          </Helmet>

          <div className={styles.grid_container}>

          <div className={styles.grid_header}>
                <h4>Find the product for You, your Company or your Government</h4> 
              </div>

            <div className={styles.grid_item}>
              <MyCard 
              key ={5}
                title={title_product_2}
                img={pic_product_2} 
                text={text_product_2}
                btn="See more..."
                detail={()=>this.detail_card(2)}>
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
                key ={6}
                title={title_product_3}
                img={pic_product_3} 
                text={text_product_3}
                btn="Read more..."
                detail={()=>this.detail_card(3)}>            
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
              key ={7}
                title={title_product_4}
                img={pic_product_4} 
                text={text_product_4}
                btn="Read more..."
                detail={()=>this.detail_card(4)}>  
              </MyCard>  
            </div>

            <div className={styles.grid_item}> 
              <MyCard 
              key ={8}
                title={title_product_5}
                img={pic_product_5} 
                text={text_product_5}
                btn="Read more..."
                detail={()=>this.detail_card(5)}>            
              </MyCard>  
            </div>
          </div>
        </div>
  }
}
 
  render() 
  {
    switch (this.selected_form)
    {
 
        case 2:  
        this.component = <div><TrustIt/></div>;
        break;

        case 3:  
        this.component = <div><BSB100/></div>;
        break;

        case 4:  
        this.component = <div><BSB300/></div>;
        break;

        case 5:  
        this.component = <div><BSB700/></div>;
        break;

        default:
        this.component = this.mainform;
    }


    this.selected_form = this.mainform;
    return  this.component ;
  }
}
