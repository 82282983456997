import React            from 'react'; 
import Newspaper        from '../components/Newspaper.jsx';
import Newspaper_4_col  from '../components/Newspaper_4_col.jsx';
import Paper            from '../components/Paper.jsx';
import styles           from './use_cases.module.css';
import {Context}        from '../services/context.js'

import pic_use_case_1 from '../pictures/Luxery shoes.webp'; 
import pic_use_case_2 from '../pictures/Palace Hotel San Francisco.webp'; 
import pic_use_case_3 from '../pictures/Airplane Maintenence.webp'; 
import pic_use_case_4 from '../pictures/Petty Cash.webp'; 
import { Helmet }           from 'react-helmet';

const images =[
  {title:'Pic 1', picture: pic_use_case_1, index:1}, 
  {title:'Pic 2', picture: pic_use_case_2, index:2},
  {title:'Pic 3', picture: pic_use_case_3, index:3},
  {title:'Pic 4', picture: pic_use_case_4, index:4}, ] ;





/*
const text_1  = [<h4>Personal Applications</h4> ,
<p>Two categories of personell use cases are ideal for personell use. Start with Trust.it, the public Back-Step Blockchain available on the internet. 
    People like me forget often things which seem not important when they happen. Later you need the information but you cannot remember the details. 
    Example: I bought 3 years ago a couple of circuit breakers for my house. I did not keep the receipt. Now I need some more circuit breakers for a Back-Step Blockchain 
    server rack, but who  was the supplier. In the future everything, including small things will be recorded on the blockchain. 
    When did I buy my Samsung phone? If its on the chain it is easy to find. Blocks can be stored in two modes, readable plain text or encrypted text.
    If the Block contains information you dont like to share publically, just click privacy mode and the data are strong encrypted with your own secret key. 
    When you read a private block the data are automatically encrypted. The blocks are easy to access. All you need is a WEB browser.
</p>,
<p> My wife is a gifted cook. She creates many nice Asien and Western dishes. I ask her to share the recepies on the Back-Step Blockchain for public access. 
    There are tons of applications you may think about. Because it is so fast and cheap it outperforms traditional blockchains and opens so many new opportunities.</p>,
<p> The second category of application requires the Back-Step Blockchain on you personal device itself. Examples can be found under a "Trustful base for digital currencies" on this web site.
    This allows the development of all kind of exchange solutions for goods and payment solutions.  


    
</p>];
*/

const text_1 = [
    <h3>Personal Applications</h3>,
    <p>
      The Back-Step Blockchain opens up two pivotal avenues for personal use. First, consider Trust.it, our publicly accessible blockchain. It's a common scenario: details 
      and receipts from past purchases, like that specific brand of circuit breakers you bought years ago, slip through the cracks of memory. With Trust.it, every 
      trivial detail can be securely logged on the blockchain, turning forgetfulness into a relic of the past. Whether it's tracking the purchase date of your Samsung 
      phone or storing recipes, the choice of privacy is yours—blocks can be encrypted for your eyes only or left in plain text for public sharing.
    </p>,

    <p>
      Speaking of sharing, my wife, an adept at blending Asian and Western culinary arts, uses Back-Step to share her recipes. The simplicity, speed, 
      and cost-efficiency of our blockchain technology break new ground, offering endless possibilities beyond traditional platforms.
    </p>,

    <p>
      The second category for personal application lies right in your handheld device. It's the foundation for digital currencies and a catalyst for developing 
      myriad exchange and payment solutions. Dive deeper into this under our "Trustful Base for Digital Currencies" section for insights on leveraging the 
      Back-Step Blockchain in your personal device for groundbreaking applications.
    </p>
  ];
  
/*
const text_2  = [ <h4>Small Business use cases</h4>,
 <p>Again, there are lots of applications if a small business runs its own Back-Step Blockchain or uses a public available service in encrypted mode. It starts from inventory 
    over accounting to staff records. Petty casch records can be stored on the blockchain. Supplier and customer data could be on the blockchain. 
    All kind of product data like manufactoring lots or version numbers could be on the blockchain. A company can decide which data from their chain the declare 
    public and which not.
</p>];
*/


const text_2 = [<h3>Small Business Blockchain Applications</h3>,
  <p>For small businesses, the adoption of a Back-Step Blockchain, whether through a proprietary system or a public service in encrypted form,
   unlocks a myriad of applications. This technology can revolutionize how businesses manage inventory, accounting, and employee records. 
   Blockchain allows for secure, immutable recording of petty cash transactions without outdated cash boxes.
   </p>,

   <p> 
   When was which product bought from wich supplier at what conditions. Blockchain does not forget. Supplier and customer information and detailed product data 
   such as manufacturing lots, version numbers related serial numbers need to be on the blockchain for future reference. Back-Step Blockchain empowers companies with 
  the flexibility to decide which data to share publicly and which to keep private, enhancing both transparency and confidentiality where needed.
  </p>,
  <img src={ pic_use_case_4} width='230' alt="Petty cash" />,];


/*
const text_3 = [ <h4>Companies with valuable products</h4>,
<p><b>Product piracy </b></p>, <p>Among thousands of applications we explain product piracy a bit more in detailed. Current systems are too expensive to become widely used against 
    product piracy. This changed with Back-Step Blockchain because of speed and low cost. Products will get a random serial number which is stored on the blockchain 
    at the birth time of the product. Such a block visible on the blockchain tells the pirates just one serial number of one product. At ech stop on the way to the final 
    shop a block with the delivery data is created. When the product is sold to the end user a block is created at the shop, with personal data required for the warrenty and 
    the certificate of origin. The certificate of origin is send to the user address containing a second random number. Fro now on the user can verify the origin of its product 
    on-line using the second number. He will find his product on the chain with all the data the manufactorer provides and with the complete delivery path.
    If somewhere on the way from the factory to the shop the replaces the product with a faked on, then two products exist with the same serial number. As soon as a second atempt 
    to register the product is made the application creates alarm saving all the data the user was asked to type in and the technical data like IP addresses and region. 
    With these data the manufacturer can launch a criminal report to the police. The user wich is already registered on the chain can be contacted. Then it can be manual 
    verified if his produt is the origin or the faked one. Manufacturer can use social media and marketing to inform the users that they use such a technology. 
    
    Back-Step Blockchain enables also small companies with valuable products to use this technology, because the cost of a block is eligable. Traditional blockchains fail 
    here at the point of sale. User cannot be required to wait minutes until their block is registered at time of purchase. Seconds count, wich is the advantage of the 
    Back-Step Blockchain. 
</p> ];
*/




const text_3 = [
  <h3>Companies with Valuable Products</h3>,

  <p><b>Combating Product Piracy</b></p>,

  <p>
    Among the myriad applications of the Back-Step Blockchain, its role in deterring product piracy stands out. Traditional anti-piracy measures often fall short 
    due to high costs and complex implementation. Back-Step Blockchain revolutionizes this space with its speed and affordability. At the product's creation, 
    a unique serial number is assigned and recorded on the blockchain. This initial block makes the item traceable through its journey from manufacturing to final 
    sale, with each transit point adding a new block detailing the product's movement.
  </p>,

  <p>
    Upon purchase, the product's sale is also registered, linking it to the buyer's warranty and including a certificate of origin with a unique verification code. 
    This enables consumers to easily verify their product's authenticity and journey from factory to their hands via the second code provided. Should a counterfeit 
    attempt to register with the same serial number, the system immediately flags it, capturing relevant data to aid in tracking and legal action.
  </p>,

  <p>
    This process not only deters piracy but also empowers smaller companies with valuable products to leverage blockchain technology affordably. 
    The Back-Step Blockchain's efficiency shines at the point of sale, where transaction speed is critical. Unlike traditional blockchains, where processing 
    can lag, Back-Step's swift block registration ensures a seamless customer experience.
  </p>,
  <img src={ pic_use_case_1} width='230' alt="Luxery_goods" />
];

/*
const text_4 = [ <h4>Government, Organizations and Others</h4>,

    <p>There are again lots of applictions that the save time and provide trust. See here my story of my future 2028 visit to to San Francisco. 
    I show you a lot of possibilyies with Back-Step Blockchain.</p>,
    
    <p>The electronic emmigration  officer quickly checked my idendity based on my new passport with its inbuilt Back-Step-Blockchain. The link to the Singapore 
    blockchain allowed a doble check of my biometric data against the data in the passport blockchain and the physical sensor system of the airport. 
    My new passport contain the Back-Step Blockchain which proves my idendity and contains a history of my visits to other countries. 
    No suspicious country was found, so I had a very fast entry without a personal interview. </p>,

    <p> 
    As soon as I had my luggage I made my way to the car rental counter. I showed my passport and the sales staff checked online the existence and the type of my driver license 
    on the Singapore police Back-Step Blockchain. The staff was friendly "No faked licenses anymore since we use Back-Step Blockchain", he commented. 
    My personal ID number from the passport and my ID card is the search argument for many applications on the Back-Step Blockchain. I was used to drive on the right side but this 
    skill is outdated with self driving cars. So, why did they ask for driver license when I cannot drive at all. </p>,
    
    <p>
    Shortly after I reached the The Palace Hotel the friendly front counter staff saw my reservation on the the hotel blockchain. The hotel staff needed to check my identity 
    which was an electronic process between the passport blockchain and the hotel blockchain. Both blockchains were of type Back-Step, so speed was no issue. The front desk lady 
    offered to place The Palace hotel data on my passport blockchain. Each blockchain can have multiple areas or sections beside the official immigration blocks. 
    Helpful if I visit in the future my US customers at the West-Coast more often. I told her I will decide at checkout time.  
    The hotel puts my bill on the hotel blockchain. I could verify my bill at any time. I copied the bill to my private blockchain on the phone. I need it for expense claims
    when I am back in Singapore. All my expense blocks during the trip are transferred to our company blockchain. The hotel is great. My restaurant reservation for 
    the evening was placed on the restaurant blockchain during check in. The hotel and their restaurant maintain a guestboock for their visitors. I placed my greetings from 
    the Back-Ste blockchain inventor and from Singapore on the next empty block. The guestbook is visible for all guests and I could read all the previous entries 
    of many interesting people. </p>,

    <p>
    I forgot to mention that my Phone contains the Back-Step Wallet. I use it to keep my Singapore Dollars and my US Dollars on the same chain. You can read more about that 
    in another article on this web page. It replaced my credit cards almost completely.</p>,

    <p>   
    Next day I registered a subsidiary company in US. I knew that this required in the past a lot of preparation in Singapore to proof that I am the director of my existng 
    company and that I am authorized to open the subsidiary. A lengthy detailed process with a lot appointments at notaries, chamber of commerce, foreign ministries and 
    the US embassy. This process required a lot of paper, stamps and seals just to build trust. With Back-Step blockchain it is less complicated. First I placed my directors 
    resolution on my company blockchain. I send the signed block from my blockchain to the notary to process the rest. All the previous paper based steps are in the meantime 
    electronic from blockchain to blockchain. Minutes later I received the notaries invoice on a block and after electronic payment with my blockchain wallet.
    Shortly later, at the end of the process the blockchain of the embassy of USA recorded a block which states that my company is real, I have the directors 
    resolution and I am authorized to represent my company.
    Now in the US the authorities acceped my data from my personal blockchain.  Every required party in USA verified my data against the embassies blockchain. 
    That was fast and a positive experiance. The company registration was easy.</p>,
   
    <p>
    Later during my visit I had interviews with job candidates. I checked their education status because I learned not to trust paper copies. I checked the Back-Step Blockchain 
    of the Universities to saw the real education status. At the evening in the hotel I saw TV news. I suspected some "news" were faked. To know for sure I visited the Back-Step 
    Blockchain of the original publisher or news agancy like AP (American Press) on line. Verified new had an QR code at the corner which I could photograph with my phone. 
    After the news were over I used an appliction on the phone to link to the previous scanne QR codes. This linked me to the Back-Step blockchains of news agancies.
    I found trusted news and news from trusted sources on the blockchain. I ignored news I could not find on the Back-Step blockchain. </p>, 

    <p> 
    After all my work completed I flew back to Singapore with Delta Airlines. I am malways scared about airplane security, because I know how technology and how humans can fail. 
    I felt a bit better after I learned that most information in the airline industry are stored on the Back-Step Blockchain. I could not check the blockchain by myself 
    but I am aware that the two pilots have the required licenses and they did their refresh training and health check as required. At take off preparation time the pilots 
    have to link their blockchain to the blockchain of the fight management computer. The flight management computer is an integrated part of the airplane. The plane would not 
    allow to start the engine without the required entries in the blockchain. No manipulation possible!</p>,
    
    <p>
    Did the airline implement the latest safety recommandations released from the FAA. When was the latest maintenance of the airplane? Were the 
    manufacturer defines spare parts used? Which maintenence staff was involved, shift changes, final test results at the end of the maintenace, all these 
    information are now on the related blockchain. In the rare case of an accident the Back-Step Blockchain used in the black boxes make sure that the true data are available to 
    the accident investigators. In the past we had a few cases where the data looked like manipulated. Here again the Back-Step Blockchain with it's low power consumption and 
    its lightning fast speed is a prerequisite for the solution.

    Now back from my trip to the future. As you can imagine by yourself how many more applications are possible wit the Back-Step Blockchain. </p>];
*/

    const text_4_1 = [
  <h3>Government, Organizations, and Beyond</h3>,
  <p>
    Discover the myriad applications of the Back-Step Blockchain that save time and instill trust. Join me on a journey to San Francisco in 2028, where I explore 
    its vast possibilities.
  </p>,
  <p>
    At the airport, an electronic immigration officer swiftly verified my identity through my new passport, integrated with the Back-Step-Blockchain. This system, 
    linked with Singapore's blockchain, double-checked my biometrics against both the passport's data and the airport's physical sensors. The Back-Step Blockchain 
    ensures the authenticity of my identity and travel history, speeding up my entry without the need for an interview.
  </p>,
  <p>
    Upon collecting my luggage, I proceeded to the car rental, where my driver's license was verified online via the Singapore police Back-Step Blockchain. 
    "No faked licenses anymore, thanks to Back-Step Blockchain," remarked the staff. This verification process, facilitated by my personal ID, underscores 
    the technology's role in simplifying everyday transactions—even as self-driving cars render traditional driving skills obsolete.
  </p>,
  <img src={ pic_use_case_2} width='230' alt="Hotel front desk" />,
  <p>
    At The Palace Hotel, the front desk seamlessly confirmed my reservation through their blockchain. A quick digital ID check, bridging my passport and 
    the hotel's systems—both powered by Back-Step—ensured a swift check-in. The hotel even offered to add its data to my passport blockchain, a 
    testament to Back-Step's flexibility in managing personal travel records and preferences.
  </p>,
  <p>
    My hotel bill was accessible on their blockchain, allowing me to transfer it to my private blockchain for expense tracking. This integration highlights 
    Back-Step's utility in managing financial records, further exemplified by my use of the Back-Step Wallet for seamless currency management.
  </p>,
  <p>
    Registering a subsidiary in the US was remarkably streamlined. By uploading directorial resolutions to my company's blockchain and interacting with 
    various legal entities through their respective blockchains, I experienced a frictionless and efficient process, unencumbered by traditional bureaucratic hurdles.
  </p>,
  <p>
    Verifying job candidates' education through university blockchains and discerning authentic news via media blockchains underscored Back-Step's role in ensuring 
    credibility. This technology offers a robust method for authenticating information in an era of misinformation.
  </p>,
    <img src={ pic_use_case_3} width='230' alt="Airplane maintenance" />,
  <p>
    As I concluded my journey with a flight back to Singapore, I was reassured knowing the aviation industry's reliance on Back-Step Blockchain for maintaining 
    licensing, training, and maintenance records. This implementation not only enhances safety protocols but also ensures transparency and trustworthiness in critical data.
  </p>,
  <p>
    Reflecting on my futuristic voyage, it's clear the Back-Step Blockchain's applications are only limited by our imagination. Its potential to revolutionize 
    industries, ensure data integrity, and streamline processes is just beginning to be uncovered.
  </p>,
];



export class UseCases extends React.Component  
{

    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
        super(props);
    }

    //------------------------------------------------
    //  Component Mount
    //------------------------------------------------
    componentDidMount()
    {

        this.prepare_for_rendering();
        this.forceUpdate();

    }



    //--------------------------------------------------------------------------
    // Prepare for rendering  -- Portrait --
    //--------------------------------------------------------------------------
    prepare_for_rendering()
    { 
      if (Context.portrait)
        {
          console.log('Portrait use cases');

          this.component = 

          <div className={styles.backdrop_portrait}>

              <Helmet>
              <title> Use cases for Back-Step Blockchain </title>
              <meta name="description" content="Show a few use cases. Many are not possible with traditional blockchains. Imagine, you have your wn blockchain in your phone." />
              <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_header_portrait}>
                  <h3> 18 of 100,000 Use Cases</h3>
              </div>

              <div className={styles.grid_item}> 
                  < Paper textLines = {text_1}/> 
              </div>

              <div className={styles.grid_item}>
                  < Paper  textLines = {text_2}/>
              </div>
              
              <div className={styles.grid_item}>
                  <Paper  textLines = {text_3}/>
              </div>

              <div className={styles.grid_item}>
                  <Paper  textLines = {text_4_1}/>
              </div>

          </div>

        }
        else
        {
        //--------------------------------------------------------------------------
        // Prepare for rendering  -- Landscape --
        //--------------------------------------------------------------------------
          this.component = 
              <div className={styles.backdrop_portrait}>

                <Helmet>
                <title>Use cases for Back-Step Blockchain</title>
                <meta name="description" content="Show a few use cases. Many are not possible with traditional blockchains. Imagine, you have your wn blockchain in your phone." />
                <meta charset="utf-8" />
                </Helmet>

                  <div className={styles.grid_header}>
                  <h3>Use cases for Back-Step Blockchain</h3>
                  </div>

                  <div className={styles.grid_item}> 
                  < Newspaper_4_col textLines = {text_1}/> 
                  </div>

                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_2}/>
                  </div>
                  
                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_3}/>
                  </div>

                  <div className={styles.grid_item}>
                      <Newspaper  textLines = {text_4_1}/>
                  </div>

               </div>
        }
    }

   
    render()
    {
        return this.component;
    }

}