import React         from 'react';
import pic_about_1   from '../pictures/MassiveChain.webp';
import pic_about_2   from '../pictures/OldChain.webp';
import  MyCard       from '../components/MyCardV2';
import { useState }  from "react";
import styles        from './about.module.css';
import TextBlock     from '../components/textBlock.jsx';
import Newspaper     from '../components/Newspaper.jsx';
import Paper         from '../components/Paper.jsx';
import {Context} from '../services/context.js';
import { Helmet }           from 'react-helmet';

const text_1  = [<h4>Blockchain Systems</h4> ,
<p>Blockchain Systems Pte. Ltd. in Singapore is a private owned Hard- and Software company 
with know-how in cryptography, electronic and chip design, Linux kernel programming and related fields. 
The company was founded in 2018 exclusively for the continuing development and marketing of the Back-Step Blockchain invention. 

<b> Encryption Technology Pte. Ltd.</b> is the elder sibling company with professional know-how in the field of network encryption at government level. 
ET produces the Hardware for the various versions of the Back-Step blockchain. </p>];

const text_2  = [ <h4>Our Business</h4>,
<p>We provide the <strong>hardware modules</strong> to build Back-Step Blockchain applications. These HW is intended for companies who create customized blockchain applications 
for their customers or of the shelf applications for the Back-Step Blockchain.
Beside the pure HW we provide the <strong>APIs</strong> required by SW developers to communicate with the Back-Step Generator. Sample SW drivers and entry level 
applications are provided to our customers and partners.
</p>,
<p>Beside the HW we provide <strong>Trust.it</strong>, a WEB service based on the entry level HW module. See more on the product page under Trust.it. 
</p>,
<p>
Finally we offer <strong>licenses</strong> for customers who want to integrate Back-Step Blockchain into their products, like black-boxes or smartphones.  
</p>];


  const currentYear = new Date().getFullYear();
  const name = ['Blockchain Systems Pte. Ltd. '];       // Replace with your name
  const location = ['Singapore'];                      // Replace with your actual location
  const contactEmail = ['info@blockchainsystems.sg'];    // Replace with your contact email
  const patentNumber = '[US 11,902 447 B2, - EU 3 871 368.B1, - Japan 7208455, - India 516737, - Singapore 11202011414Y]'; // Replace with your patent number

const text_3  = [ <h4>Impressum</h4>,

      <p>Impressum |  Back-Step Blockchain | Founder: {name} {location} (CRN: 201832149D) | Contact: {contactEmail} | Copyright &copy; {currentYear} by {name}.
      All rights reserved.
      The Back-Step Blockchain is patented and its technology is protected under {patentNumber}. Unauthorized use, duplication, or distribution of this material without 
      explicit permission from the patent holder is strictly prohibited.</p>];
 
export class About extends React.Component 
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      //console.log('Component did mount in About Us');
      this.prepare_for_rendering();
      this.forceUpdate();
    };
   
    //-----------------------------------------------------------
    // rendering for portrait
    //-----------------------------------------------------------
    prepare_for_rendering()
    { 

      if (Context.portrait)
        {
          this.component = 
          <div className={styles.backdrop}>

              <Helmet>
                <title>About page</title>
                <meta name="description" content="Explains Blockchain Systems " />
              </Helmet>

              <div className={styles.grid_container}>

                <div className={styles.grid_header_portrait}>
                    <h4> Know more about us... </h4>
                </div>

                <div className={styles.grid_item}> 
                  < Paper textLines = {text_1}/> 
                </div>
  
                <div className={styles.grid_item}>
                  <Paper  textLines = {text_2}/>
                </div>
  
                <div className={styles.grid_item}>
                  <Paper  textLines = {text_3}/>
                </div>
                </div>
             </div>
        }
        else
        {
        //-----------------------------------------------------------
        // rendering for landscape
        //-----------------------------------------------------------
         this.component = 
          <div className={styles.backdrop}>

              <Helmet>
                <title>About page</title>
                <meta name="description" content="Explains Blockchain Systems " />
              </Helmet>


              <div className={styles.grid_container}>
              
                 <div className={styles.grid_header}>
                    <h4>You may want to know... </h4>
                </div>

                <div className={styles.grid_item}> 
                < Newspaper textLines = {text_1}/> 
                </div>

                <div className={styles.grid_item}>
                  <Newspaper  textLines = {text_2}/>
                </div>

                <div className={styles.grid_item_2col}>
                  <Paper  textLines = {text_3}/>
                </div>

              </div>

            </div>

        }

  }
    
    render()
    {
      return this.component ;
    }
}

/*
<div className={styles.center}>
<h3> Every block is safe in our chains, forever.</h3>
</div>
<img src={pic_about_2} alt="Old Chain" />
*/