import React     from 'react'; 
import styles    from './bsb_300.module.css';
import Newspaper from '../components/Newspaper.jsx';
import { Helmet }           from 'react-helmet';

import pic_coming_soon from '../pictures/Comming soon-1.webp';
  
  

export class BSB300 extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      //console.log('Component did mount in BSB300');
      this.prepare_for_rendering();
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 
      this.component = 
         <div className={styles.backdrop}>
             <Helmet>
                  <title>BSB 300</title>
                  <meta name="description" content="The BSB 300 hardware is soon ready and will provide higher speed and network connection." />
                  <meta charset="utf-8" />
                </Helmet>

            <img src={pic_coming_soon} alt="picture coming soon" />
          
      </div>
    }
    
    render()
    {
      return this.component ;
    }
}  

