class context
{
    constructor(props) 
    {
   
        this.portrait = window.innerHeight > window.innerWidth;
        
        window.addEventListener('resize', function() 
        {
            console.log('Orientation change');
            this.portrait = window.innerHeight > window.innerWidth;
        });
        

    }
}
export let  Context  = new context();

