import React          from 'react'; 
import styles         from './trust_it.module.css';
import Newspaper      from '../components/Newspaper.jsx';
import Paper          from '../components/Paper.jsx';
import {Context}      from '../services/context.js';

import pic_trust_it_1 from '../pictures/Luxery shoes.webp'; 
import pic_trust_it_2 from '../pictures/Palace Hotel San Francisco.webp'; 
import pic_trust_it_3 from '../pictures/Airplane Maintenence.webp'; 
import pic_trust_it_4 from '../pictures/Petty Cash.webp'; 
import { Helmet }           from 'react-helmet';

const images =[
  {title:'Pic 1', picture: pic_trust_it_1, index:1}, 
  {title:'Pic 2', picture: pic_trust_it_2, index:2},
  {title:'Pic 3', picture: pic_trust_it_3, index:3},
  {title:'Pic 4', picture: pic_trust_it_4, index:4}, ] ;

/*
const text_trust_it_1  = [ <h4 key="header1"> Trust.it on the Web - Currently in Beta Testing</h4>,

<p> Please be informed that Trust.it is in its experimental phase. While we strive for stability,
    there's a slight possibility of service interruptions as we implement new features. To safeguard
    your data, we recommend backing up your chain locally. </p>, 

<h4>Trust.it </h4>, 
<p>demonstates how easy it is to build applications based on the Back-Step Blockchain. </p>,

<p> Trust.it acts as save storage for information that cannot be mutated. Any atempt to alter any data is immediatelly detcted. Each block provides 1008 byte of data.
    A few byte are required for organizational purpose, for example the user ID or the type of data in the block. In the basic text mode the chain stores text. 
    This text can be encrypted if you do not want others to see your text. If you want to secure large files you store the hash and the URL of each file on the 
    Back-Step Blockchain while the actual data are stored where the URL points to. Such a block can also be encrypted, that others do not know about your file. 
    With an external program running on your computer you can re-hash your files one by one and compare the hash is automatically compared against the blockchain content. 
    If you move or delete your file the blockchain has a dangling reference, but no further implications. The blockchain is still consistent.
    We plan to provide a set of tools for picture, video and sound content to be handled the same way. Of course everyone can create such programms by themself. </p>,


    <h4>Privacy Mode</h4>,


<p> As mentioned already Trust.it supports a privacy mode where the payload of the block is encrypted and only the account owner sees the data in plaintext. This encryption is 
    sophisticated and does not unveil any informationa bout your data. When you encryt exactly the same text multiple times each one of the encrypted blocks is 
    completely different. We plan to provide a group mode were you can create a group of usesrs that can share the same private data. The one who creates a 
    group can invite and remove members. </p>,

<h4>Block Fee</h4>,

<p> We charge money for every block stored on Trust.it and call this money Fee (with capital F). The Fee shall avoid flushing attacks and pay for our cost. 
    The value of the Fee is coupled to the USD. One Fee is equivalent to one US cent. Storing a standard block on Trust.it requires 2 Fees while an 
    encrypted blocks cost 3 Fees. The Fee covers the cost for storage without time limit, the network transaction to and from the Back-Step Blockchain, 
    the system and data center rent, the maintenance, the electricity and the required profit. We will not show advertising on our application but we may 
    sell advertising blocks at a special advertising fee. We ensure that advertising blocks are not more than 1 every 100 blocks and do not disturb when 
    you scan the chain.</p>,

<p> When you buy a Back-Step Blockchain Generator and create your own application then you are free defining your own pricing and conditions. You still have to pay
    Fees to Blockchain Systems but on a volume discounted rate. Enquiries are welcome.   
</p>];



const text_trust_it_2  = [ <h4>Key technical data </h4>,

<p> Here are some additional information for the interested audience: Each block contains 3 type of data, system data, payload data and payload security data. 
    System data are used to secure the total block as a hole and are idential on all chains, independand what the application is. Payload data are the application data and 
    depend only on what the aplication does with them. Payload security data secure the payload portion of the block. The payload 
    security data provide two different modes, with a foreward hash chain or with MoMu chaining. </p>,

<p> To build an application like Trust.it we defined our own structure for the payload data representing all the fields required. Of course 
    this is 100% transparent, as everybody can read all the blocks. We need a user ID field. This is used when you select at the client to show only blocks
    with a specific user ID, normally your own. Also an indication for user groups is required. Finally there is a field type defining what pupose the block is used for.</p>,
 
    <p>Examples of block usage:</p>,
    
    [<li>Native block</li>,
    <li>Reference block</li>,   
    <li>Concatination block</li>,
    <li>Group block</li>, 
    <li>Invalidate block</li>,
    <li>Advertisement block</li>, 
    <li>Message block</li>, 
    <li>Payment block</li>, 
    <li>Fee transfer block</li>,
    <li>Encrypted block</li>
     ],
     <p>Encrypted blocks are 16 byte longer. After decryption the blocks look identical to the non-encrypted block</p>,

<p> We reserved 2 terabyte of blockchain space on the storage system for our users. For comparison, the file size of the Bitcoin blockchain was ~500 GB as of end of 2023.
    The Back-Step blocks are also larger than the blocks of most other chains with a size of 1232 byte for each block. About 1040 byte of that are available as payload. 
    This gives you more space for your application records and allows other chains to become children of a Back-Step Blockchain like you know it from 
    Virtual Machines on computer systems. Lots of opportunities for our collegue developers.</p>,

<p> With the 2TB block space Trust.it can manage 1,6 Billion blocks. Lets assume 10.000 users in the first year, then as an average each user has space for 162,000 blocks.
    Lets further assume that all these users run smal businesses and create 100 blocks per day, then it willlat for more than 4 years. Before that time we will increase 
    the Trust.it capacity to 64 Terrabyte because we foresee that many businesses will use Trust.it for several use cases. The power of private poeple will increase with 
    the additional funtions planned for Trust.it. All together we belive Trust.it willgrow fast. </p>,
    
<p> Each user can verify the chain or a copy of it. To avoid verification attacks and server overload the system will limit 
    the number of parallel verifications to a certain percentage of the bandwidth. User can create local copies of the chain and also verify their copies. Cpoies can 
    continuesly be updated as the chain grows. To optimize the chain verification checkpoints can be set at the most recent verifications. This saves 
    computing time at the client side.</p>,

<p> After the start of Trust.it we will focus on installing 2 other server sides for redundancy to guarante availability in case of technical failures and physical desasters.

</p>]
*/

//=========================================================================================

const text_trust_it_1 = [
    <h4 key="header1">Chirp with Trust.it</h4>,

    <p key="paragraph1">

Welcome to Trust.it, where communication is redefined. Our main feature, the Chirp, sets us apart from conventional messaging systems. 
Chirp is cost-effective and transparent: you only pay for what you use. No Chirping? No charges. You’ll enjoy our consumption-based pricing 
rather than standard subscriptions.</p>,

<h4>Simple Costs, Free Reading</h4>,

<p>Sending a Chirp incurs a minimal fee, but reading messages is always free. Thanks to our innovative Back-Step Blockchain technology, 
  once a Chirp is sent, it cannot be altered or deleted. Think carefully before sending, as each Chirp is permanent. Errors can be addressed 
  in subsequent Chirps, but the original message will remain on the blockchain.</p>,

<h4>Upcoming Features and Group Chirping</h4>,

<p>Trust.it is continually evolving. We're excited to announce upcoming expansions to include media options. Group Chirping and encryption 
will soon enhance your communication experience.</p>,

<h4>Early Adoption Notice</h4>,

  <p>
   Please note that Trust.it is in its experimental phase. While we are committed to reliability, the introduction of new features may briefly 
   disrupt services. To protect your data, we recommend local backups of your blockchain.
  </p>,

    <h4 key="header2">More Trust.it</h4>,
    <p key="paragraph2">
      Trust.it exemplifies the simplicity and robustness of building applications atop the innovative
      Back-Step Blockchain. This platform serves as a secure repository for immutable records,
      guaranteeing the integrity of your data against any modification attempts. Each block accommodates
      up to 1008 bytes of data, with a small portion reserved for administrative details such as user IDs
      or data types.
    </p>,
    
    <p key="paragraph3">
      In its essence, Trust.it offers three modes of operation:
      <ul>
        <li key="mode0"><strong>Chirp</strong> the social media implementation on Trust.it </li>.
        <li key="mode1"><strong>Basic Text Mode:</strong> Ideal for storing textual data, with optional encryption for enhanced privacy.</li>
        <li key="mode2"><strong>File Security Mode:</strong> Securely store large files by saving their hash and URL on the blockchain,
         with the actual data hosted externally. This setup supports encrypted blocks for added confidentiality.</li>
      </ul>
      We are also developing tools to manage multimedia content seamlessly and encourage the community to contribute.
    </p>,

    <h4 key="header3">Enhanced Privacy Features</h4>,
    <p key="paragraph4">
      Trust.it incorporates a privacy mode, encrypting the block's payload to shield your data. This advanced
      encryption ensures that even identical texts, when encrypted, yield distinct blocks, maintaining your
      privacy without compromise. Future updates will introduce group functionalities, allowing shared access
      among selected users.
    </p>,

    <h4 key="header4">Fair Pricing Structure</h4>,

    <p key="paragraph5">
      Trust.it introduces a modest fee structure to maintain service quality and prevent spam. The fees, pegged
      to the US dollar, are as follows:
      <ul>
        <li key="fee1">Standard Block: 2 Fees (equivalent to 2 US cents)</li>
        <li key="fee2">Encrypted Block: 3 Fees</li>
      </ul>
      This fee covers indefinite storage, network transactions, maintenance costs, and more, ensuring a no-advertising
      experience on our platform. But we also offer specialized rates for advertising blocks, with a commitment to maintaining
      a non-intrusive user experience.
    </p>,
    <p key="paragraph6">
      Owners of the Back-Step Blockchain Generator creating their own applications enjoy the freedom to set their pricing, benefiting from volume discounts
      for transactions processed through Back-Step Blockchains.
    </p>
  ];
  
  const text_trust_it_2 = [
    <h4 key="header5">Technical Insights</h4>,

    <p key="paragraph7"> For those interested in a deeper dive, each block in our blockchain comprises three distinct types of data: </p>,
    <ul>
        <li> <strong>System Data:</strong> This is the backbone of each block, ensuring its integrity</li>
        <li> <strong>Payload Data: </strong>These is the content specific to the application</li>
        <li> <strong>Payload security:</strong> Payload security data secure the payload portion of the block. The payload  security data provide two different modes, 
            with a forward hash chain or with MoMu chaining.</li>
    </ul>,

    <p key="paragraph8" > To build an application like Trust.it we defined our own structure for the payload data representing all the fields required. Of course 
    this is 100% transparent, as everybody can read all the blocks. These fields reduce the data available for the application. They can be considered as payload metadata.
    There is a user ID field. This is used when you select to show only blocks with a specific user ID normally your own. Also an indication for user groups if required. 
    Finally there is a field defining the type of block from an application type of view. Trust.it for example uses 10 different blocks defining what purpose the block is used for.</p>,
    
    <p key="paragraph9"> Examples of block usage:</p>,
    
    [ 
    <li>Native block</li>,
    <li>Reference block</li>,   
    <li>Concatination block</li>,
    <li>Group block</li>, 
    <li>Invalidate block</li>,
    <li>Advertisement block</li>, 
    <li>Message block</li>, 
    <li>Payment block</li>, 
    <li>Fee transfer block</li>,
    <li>Encrypted block</li>
     ],

     <p>Encrypted blocks are 16 byte longer, they contain a random dummy block for security purpose. Block encryption uses AES 256 in CBC mode. 
        After decryption the blocks look identical to non-encrypted blocks having at the beginning 16 hexadecimal o bytes.
    </p>,
    <p key="paragraph8">
      Notably, Trust.it has allocated 2TB of blockchain space, significantly larger than traditional blockchains, to accommodate
      a vast array of applications. With plans to expand capacity to 64TB, Trust.it is poised for rapid growth, supporting businesses
      and individual users alike.
    </p>,
    <p key="paragraph9">
      To ensure integrity and system efficiency, Trust.it incorporates mechanisms for automatic or manual chain verification.
      In addition each user can verify the chain or a copy of it. To avoid verification attacks and server overload the system will limit 
      the number of parallel verifications to a certain percentage of the bandwidth. User can create local copies of the chain 
      and also verify their copies. Copies can continuously be appended as the chain grows. To optimize the chain verification 
      checkpoints can be set at the most recent verifications. This saves computing time at the client side.
    </p>
  ];
  

export class TrustIt extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  

    componentDidMount()
    {
      //console.log('Component did mount in TrustIt');
      this.prepare_for_rendering();
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 
      if (Context.portrait)
        {
          console.log('Trust.it portrait');

            this.component = 

              <div className={styles.backdrop.portrait}>

              <Helmet>
              <title>Trust.it online Back-Step for everybody</title>
              <meta name="description" content="Trust.t is your blockchain on the web. Fast, cheap and faszinating easy. The application is 100% fee.  " />
              <meta charset="utf-8" />
              </Helmet>
 
                  <div className={styles.grid_header_portrait}>
                      <h4> Trust.it - The Chain for You! </h4>
                  </div>

                  <div className={styles.grid_item}> 
                      <Paper key ={1} textLines = {text_trust_it_1}/> 
                  </div>

                  <div className={styles.grid_item}>
                      <Paper key ={2} textLines = {text_trust_it_2}/>
                  </div>
              </div>
        }
        else
        {
          this.component = 
          <div className={styles.backdrop}>
            
             <Helmet>
              <title>Trust.it online Back-Step for everybody</title>
              <meta name="description" content="Trust.t is your blockchain on the web. Fast, cheap and faszinating easy. The application is 100% fee.  " />
              <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_container}>
                  <div className={styles.grid_header}>
                  <h4> Trust.it - The Chain for You! </h4>
                </div>
                <div className={styles.grid_item}> 
                < Newspaper key ={3} textLines = {text_trust_it_1}/> 
                </div>

                <div className={styles.grid_item}>
                  <Newspaper  key ={4} textLines = {text_trust_it_2}/>
                </div>

              </div>
        </div>

        }
    }
    
    render()
    {
      return this.component ;
    }
}  

