import React, { Component } from 'react';
import styles from '../components/Paper.module.css';



class Paper extends Component 
{
  renderElement(element, index) 
  {
    // Assuming that an image element is an object with a 'src' property
    if (typeof element === 'object' && element.src) 
    {
      //console.log('image detetcted');
      return <img key={index} src={element.src} width={element.width} alt={element.alt || 'Image'} className={styles.image} />;
    }

    // Otherwise, treat it as text
    return <p key={index} className={styles.p}>{element}</p>;
  }

  render() 
  {
    const { textLines } = this.props;

    return (
      <div className={styles.text_block}>

          {textLines.map((line, index) => this.renderElement(line, index))}

      </div>
    );
  }
}

export default Paper;
