import React                  from 'react'; 
import styles                 from './story.module.css';
import Newspaper_4_col        from '../components/Newspaper_4_col.jsx';
import Newspaper              from '../components/Newspaper.jsx';
import Paper                  from '../components/Paper.jsx';
import {Context}              from '../services/context.js'

import pic_patent_singapore_1 from '../pictures/Patent Singapore.jpg'; 
import pic_patent_US_1        from '../pictures/US patent-1.jpg'; 
import pic_patent_US_2        from '../pictures/US patent-2.jpg'; 
import pic_patent_India       from '../pictures/Patent India.jpg'; 
import pic_patent_EU          from '../pictures/Patent EU.jpg'; 
import pic_patent_Japan       from '../pictures/Patent Japan.png'; 
import TextBlock              from '../components/textBlock.jsx';
import { Helmet }             from 'react-helmet';


const text_1  = [<h4>From Idea to Product</h4> ,
<p>Driven by curiosity, I delved into the intricacies of how Bitcoin mining processes are implemented in chips and was immediately confronted by the significant 
energy and time demands of the process. It became clear to me that this blockchain model harbored several inefficiencies, primarily its energy consumption. 
This sparked my research into alternative solutions. Almost overnight, the foundational concept of the Back-Step Blockchain materialized in my mind. 
Transforming this concept into a fully fleshed-out idea required extensive work to define and verify all the details, particularly the seal concept, which 
was elusive at first. Software simulations confirmed the feasibility of my idea. However, I also aimed for efficiency; certain values had to be ready 
precisely when needed for the next block processing. This led to the development of a unique cache model that is both filled and utilized concurrently, 
ensuring the block processing can occur without delays. After four weeks of intense problem-solving, I had a working solution, a novel innovation worthy 
of patent protection. The journey to securing patents was a learning curve, initially attempting to work with a patent lawyer, which proved less effective 
than anticipated. Eventually, I found highly professional lawyers in Germany, the U.S., and other countries, underscoring the lesson that expertise varies 
widely across any profession.</p>,

<p>My quest for a tangible prototype coincided with the challenges posed by the Covid-19 pandemic, halting development for three years. </p>,
<p>
In 2021, I developed the Chain-In-Chain concept, akin to virtual machines (VMs), and upon resuming in 2023, 
I encountered the market's initial reluctance to embrace the product. I realized the importance of demonstrating real-world applications to 
showcase the potential of the Back-Step Blockchain. This realization led to the creation of <b>Trust.it</b>, the first practical application built on the 
Back-Step Blockchain.</p>,

<p>Georg Krause Inventor</p>]



const text_2  = [ <h4>The Patents</h4>,
<p>The patents have been successfully granted in Singapore, Japan, the United States, India, and under the Unitary European patent system. 
  Applications are still pending in the UK, Korea, China, Israel, and Vietnam. I am optimistic that these will be granted within the year.
</p>,
];



export class Story extends React.Component
{
    //------------------------------------------------------------------
    //  Constructor
    //------------------------------------------------------------------
    constructor(props) 
    {
      super(props);
      this.component= "";
    }  
    

    componentDidMount()
    {
      //console.log('Component did mount in About Us');
      this.prepare_for_rendering();
      this.forceUpdate();

    };
   
    //------------------------------------------------------------------
    //  Prepare the render output
    //------------------------------------------------------------------
    prepare_for_rendering()
    { 
      if (Context.portrait)
        {
           // console.log('Portrait use cases');

            this.component = 
            <div className={styles.backdrop_portrait}>

              <Helmet>
              <title>The Story how Back-Step was created</title>
              <meta name="description" content="How Bitcoin helped indirect to create a new revolutionary new underlying blockchain. Showing all the patents received." />
              <meta charset="utf-8" />
              </Helmet>

              <div className={styles.grid_header_portrait}>
                <h4>The Back-Step Story...</h4> 
              </div>

              <div className={styles.grid_item_portrait}> 
                < Paper textLines = {text_1}/> 
              </div>

              <div className={styles.grid_item_portrait}>
                <Paper textLines = {text_2}/>
              </div>


              <div className={styles.grid_image_portrait}>
                <img src={ pic_patent_singapore_1} width='230' alt="Singapore Patent"  /> 
              </div>
            
              <div  className={styles.grid_image_portrait}>
                <img src={ pic_patent_US_1}  width='230' alt="USA Patent cover" />
              </div>

              <div  className={styles.grid_image_portrait}>
                <img src={ pic_patent_US_2}  width='230' alt="USA Patent detail"  />,
              </div>
              
              <div  className={styles.grid_image_portrait}>
                <img src={ pic_patent_India} width='230' alt="India Patent detail"  />,
              </div>


              <div  className={styles.grid_image_portrait}>
                <img src={ pic_patent_EU}    width='230' alt="European Patent detail" />,
              </div>

              <div  className={styles.grid_image_portrait}>                
                <img src={ pic_patent_Japan} width='230' alt="Japan Patent detail"    />,
              </div>

            </div>


        }
        else
        {


      this.component = 
         <div className={styles.backdrop}>

              <Helmet>
              <title>The Story how Back-Step was created</title>
              <meta name="description" content="How Bitcoin helped indirect to create a new revolutionary new underlying blockchain. Showing all the patents received." />
              <meta charset="utf-8" />
              </Helmet>
 

            <div className={styles.grid_container}>

              <div className={styles.grid_header}>
                <h4>The Story of the Back-Step Blockchain</h4> 
              </div>

              <div className={styles.grid_item_full}> 
                < Newspaper_4_col textLines = {text_1}/> 
              </div>
  
              <div className={styles.grid_item_full}>
                <TextBlock textLines = {text_2}/>
              </div>


              <div className={styles.grid_image_full}>
                <img src={ pic_patent_singapore_1} width='230' alt="Singapore Patent"  /> 
              </div>
             
              <div  className={styles.grid_image_full}>
                <img src={ pic_patent_US_1}  width='230' alt="USA Patent cover" />
              </div>

              <div  className={styles.grid_image_full}>
                <img src={ pic_patent_US_2}  width='230' alt="USA Patent detail"  />,
              </div>
               
              <div  className={styles.grid_image_full}>
                <img src={ pic_patent_India} width='230' alt="India Patent detail"  />,
              </div>


              <div  className={styles.grid_image_full}>
                <img src={ pic_patent_EU}    width='230' alt="European Patent detail" />,
              </div>

              <div  className={styles.grid_image_full}>                
                <img src={ pic_patent_Japan} width='230' alt="Japan Patent detail"    />,
              </div>

            </div>
         </div>
         }
    }
    
    render()
    {
      return this.component ;
    }
}  


/*


             <div className={styles.grid_item}>
                <Newspaper textLines = {text_2}/>
              </div>


<p>  <img src={ pic_patent_singapore_1} width='230' alt="Singapore Patent" />
</p>,
<p>  <img src={ pic_patent_US_1} width='230' alt="USA Patent cover" />,
</p>,
<p>
  <img src={ pic_patent_US_2} width='230' alt="USA Patent detail" />,
  </p>,
  <p>
  <img src={ pic_patent_India} width='230' alt="India Patent detail" />,
  </p>,
    <p>
    <img src={ pic_patent_EU} width='230' alt="European Patent detail" />,
    </p>,
  <p>
     <img src={ pic_patent_Japan} width='230' alt="Japan Patent detail" />,
  </p>,
  */